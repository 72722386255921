import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Board, BoardMember, Person } from '../all.model';
import { BoardMemberComponent } from '../board-member/board-member.component';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './board.component.html',
})
export class BoardComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  board: Board;

  boards: Board[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title,
    private router: Router,
    private notifier: NotifierService,
    private modalService: NgbModal,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.all();

    this.route.paramMap.subscribe((paramsMap: ParamMap): void => {
      if (paramsMap.get('id')) {
        if (paramsMap.get('id') === '0') {
          if (this.form) {
            this.form.control.markAsDirty();
          }
          this.board = {} as Board;
          this.titleService.setTitle(this.translateService.translate('BOARDS'));
        } else {
          this.load(paramsMap.get('id'));
        }
      } else if (localStorage.getItem('board')) {
        this.router.navigate([`board/${localStorage.getItem('board')}`]);
      } else {
        this.board = null;
        this.titleService.setTitle(this.translateService.translate('BOARDS'));
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.form.control.dirty) {
      $event.returnValue = true;
    }
  }

  private all(): void {
    this.http.get('/api/boards').subscribe((data: Board[]): void => {
      this.boards = data;
    });
  }

  private load(id: number | string): void {
    if (this.form) {
      this.form.control.markAsPristine();
    }

    this.http.get(`/api/board/${id}`).subscribe(
      (data: Board): void => {
        localStorage.setItem('board', id.toString());
        this.board = data;
        this.titleService.setTitle(
          data ? data.name : this.translateService.translate('BOARDS'),
        );
        this.all();
      },
      (): void => {
        localStorage.removeItem('board');
        this.router.navigate(['board']);
      },
    );
  }

  save(): void {
    if (!this.form.control.dirty) {
      return;
    }

    this.http.post('/api/board', this.board).subscribe((data: Person): void => {
      this.form.control.markAsPristine();
      this.notifier.notify('success', this.translateService.translate('SAVED'));
      this.board = data;
      this.router.navigate([`board/${data.id}`]);
    });
  }

  delete(): void {
    this.utility.delete(this.board.name).subscribe((sure: boolean) => {
      if (sure) {
        this.http
          .delete(`/api/board/${this.board.id}`)
          .subscribe((success: boolean): void => {
            if (success) {
              this.notifier.notify(
                'success',
                `${this.board.name} ${this.translateService.translate(
                  'DELETED',
                )}`,
              );
              this.form.control.markAsPristine();
              localStorage.removeItem('board');
              this.router.navigate(['board']);
            } else {
              this.notifier.notify(
                'error',
                `${this.board.name} ${this.translateService.translate(
                  'NOT_DELETED',
                )}`,
              );
            }
          });
      }
    });
  }

  boardMember(member: BoardMember): void {
    if (!this.board.id) {
      return;
    }

    const modal = this.modalService.open(BoardMemberComponent);
    modal.componentInstance.id = member ? member.id : null;
    modal.componentInstance.board = this.board;

    modal.result.then(
      () => {
        this.load(this.board.id);
      },
      () => {},
    );
  }
}
