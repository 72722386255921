import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Board, BoardMember, Person } from '../all.model';

@Component({
  templateUrl: './board-member.component.html',
})
export class BoardMemberComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  id: number;
  person: Person;
  board: Board;

  member: BoardMember = {};

  new = false;

  people: Person[] = [];
  boards: Board[] = [];

  constructor(private http: HttpClient, private modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.id) {
      this.new = true;
      this.member._person = this.person;
      this.member._board = this.board;
    } else {
      this.http
        .get(`/api/board-member/${this.id}`)
        .subscribe((data: BoardMember): void => {
          this.member = data;
        });
    }

    if (!this.person) {
      this.http.get('/api/persons').subscribe((data: Person[]): void => {
        this.people = data;
      });
    }

    if (!this.board) {
      this.http.get('/api/boards').subscribe((data: Board[]): void => {
        this.boards = data;
      });
    }
  }

  save(): void {
    this.http
      .post('/api/board-member', this.member)
      .subscribe(this.modal.close);
  }

  delete(): void {
    this.http
      .delete(`/api/board-member/${this.member.id}`)
      .subscribe(this.modal.close);
  }

  close(): void {
    this.modal.dismiss();
  }
}
