<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      {{ 'QUERIES' | translate }}

      <span info="QUERIES_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="row mb-5">
    <div class="col-md-8">
      <form #form="ngForm" (submit)="save()">
        <div class="row">
          <div class="col-md-6 mb-3 order-2 order-md-1">
            <input
              type="text"
              [(ngModel)]="query.name"
              name="name"
              class="form-control"
              [placeholder]="'NAME' | translate"
            />
          </div>
          <div class="col-md-6 mb-3 order-1 order-md-2">
            <ng-select
              [items]="queries | sort: ['name']"
              (change)="router.navigate(['query/' + $event.id])"
              [placeholder]="'SEARCH_QUERY' | translate"
              [clearable]="false"
              [searchFn]="search"
            >
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                <span [class.font-italic]="item.sex != 'm'">
                  {{ item.name }}
                </span>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div>
          <button
            class="btn btn-big btn-outline-success mr-2"
            (click)="save()"
            [disabled]="!query.name || !query.query || !form.form.dirty"
            type="submit"
          >
            <span class="d-none d-sm-block">{{ 'SAVE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-pencil fa-lg"></i>
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-danger mr-2"
            (click)="delete()"
            [disabled]="!query.id"
          >
            <span class="d-none d-sm-block">{{ 'DELETE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-times fa-lg"></i>
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-primary float-right"
            (click)="try()"
            [disabled]="!query.query"
          >
            {{ 'EXECUTE' | translate }}
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-secondary mr-2 float-right"
            (click)="export()"
            [disabled]="!query.id || form.form.dirty"
          >
            <span class="d-none d-sm-block">{{ 'EXPORT' | translate }}</span>
            <i class="d-block d-sm-none fa fa-download fa-lg"></i>
          </button>
        </div>
        <div class="form-group border">
          <ngx-codemirror
            [options]="codeMirror"
            [(ngModel)]="query.query"
            name="query"
            (keypress)="ctrlEnter($event)"
            (keyup)="checkDirty($event)"
          >
          </ngx-codemirror>
        </div>
      </form>

      <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    </div>
    <div class="col-4 d-none d-md-block">
      <div *ngFor="let table of info" class="card">
        <a
          class="card-header text-dark"
          data-toggle="collapse"
          href="#collapse-{{ table.name }}"
        >
          {{ table.name }}
        </a>
        <div
          class="card-body collapse py-0 my-3"
          id="collapse-{{ table.name }}"
        >
          <div
            class="row"
            *ngFor="let column of table.columns"
            [class.text-danger]="column.Key == 'PRI'"
            [class.text-info]="column.Key == 'MUL'"
          >
            <div class="col-6">{{ column.Field }}</div>
            <div class="col-6">
              <i>{{ column.Type }}</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid p-0" *ngIf="results && results.data.length">
  <div class="page-content small">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th *ngFor="let column of results.columns">{{ column }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of results.data">
            <td *ngFor="let column of results.columns">{{ row[column] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
