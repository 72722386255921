import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SureComponent } from './sure/sure.component';
import { TranslateService } from './translate.service';

@Injectable()
export class UtilityService {
  errorFields: string[] = [];

  constructor(
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {}

  simple = (string: string): string =>
    string
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');

  showErrors = (fieldNames: string[]): void => {
    this.errorFields
      .map((fieldName: string) =>
        Array.from(document.getElementsByName(fieldName)),
      )
      .forEach((fields: HTMLElement[]) => {
        fields.forEach((field: HTMLElement) => {
          field.classList.remove('form-control-error');
        });
      });
    this.errorFields = fieldNames;
    this.errorFields
      .map((fieldName: string) =>
        Array.from(document.getElementsByName(fieldName)),
      )
      .forEach((fields: HTMLElement[]) => {
        fields.forEach((field: HTMLElement) => {
          field.classList.add('form-control-error');
        });
      });
  };

  delete(name: string): Observable<boolean> {
    return this.sure(
      this.translateService
        .translate('ARE_YOU_SURE_DELETE')
        .replace('{}', name),
      'danger',
    );
  }

  confirm(
    text: string = this.translateService.translate('UNSAVE'),
  ): Observable<boolean> {
    return this.sure(text, 'info');
  }

  private sure(text: string, type: string): Observable<boolean> {
    const modal = this.modalService.open(SureComponent, {
      windowClass: `ask-modal ${type}-modal`,
      size: 'sm',
    });
    modal.componentInstance.text = text;
    modal.componentInstance.type = type;

    return from(modal.result).pipe(catchError(() => of(false)));
  }

  download(file: HttpResponse<Blob>): void {
    const name = file.headers.get('content-disposition').slice(22, -1);
    const linkElement = document.createElement('a');
    const url = window.URL.createObjectURL(file.body);

    linkElement.setAttribute('href', url);
    linkElement.setAttribute('download', name);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  }
}
