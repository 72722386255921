import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UtilityService } from './utility.service';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<{ form: NgForm }> {
  constructor(private utility: UtilityService) {}

  canDeactivate(component: { form: NgForm }): Observable<boolean> {
    if (component.form.control.dirty) {
      return this.utility.confirm();
    } else {
      return of(true);
    }
  }
}
