import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true,
};

@Component({
  selector: 'app-date-picker',
  template: `
    <div class="input-group m-0">
      <input type="date" [(ngModel)]="date" [id]="id" class="form-control" />
      <div class="input-group-append">
        <button
          (click)="now()"
          class="btn btn-outline-secondary btn-form"
          type="button"
          *ngIf="!date"
        >
          <i class="fa fa-calendar"></i>
        </button>
        <button
          (click)="clear()"
          class="btn btn-outline-secondary btn-form"
          type="button"
          *ngIf="date"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  `,
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() id: string;

  private inner: any = '';

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  get date(): any {
    return this.inner;
  }

  set date(v: any) {
    if (v !== this.inner) {
      this.inner = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.inner) {
      this.inner = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  now(): void {
    this.date = new Date().toJSON().substring(0, 10);
  }

  clear(): void {
    this.date = null;
  }
}
