import { Directive, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoComponent } from './info/info.component';

@Directive({
  selector: '[info]',
  host: {
    '[class.info]': 'true',
  },
})
export class InfoDirective {
  @Input() info: string;

  constructor(private modalService: NgbModal) {}

  @HostListener('click')
  click(): void {
    const modal = this.modalService.open(InfoComponent);

    modal.componentInstance.info = this.info;
  }
}
