<div class="modal-header">
  <h4 class="modal-title">{{ 'YOU_SURE' | translate }}</h4>
</div>
<div class="modal-body">
  {{ text }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" (click)="dismiss()">
    {{ 'BACK' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-outline-{{ type }}"
    (click)="close()"
    ngbAutoFocus
  >
    {{ 'CONTINUE' | translate }}
  </button>
</div>
