import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { User } from '../all.model';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent {
  @ViewChild('form') form: NgForm;

  credentials: User = {
    mail: '',
    password: '',
  };

  constructor(
    private notifier: NotifierService,
    private http: HttpClient,
    private utility: UtilityService,
    private modal: NgbActiveModal,
    private translateService: TranslateService,
  ) {}

  login(): void {
    this.http.post('/api/login', this.credentials).subscribe(
      (response: any): void => {
        this.notifier.notify('info', `Welkom, ${response.name}!`);

        this.modal.close(response.token);
      },
      () => {
        this.utility.showErrors(['mail', 'password']);
        this.notifier.notify(
          'error',
          this.translateService.translate('NOT_LOGGEDIN'),
        );
      },
    );
  }
}
