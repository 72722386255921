import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { en } from './en';
import { nl } from './nl';

@Injectable()
export class TranslateService {
  private _language: string = 'nl';
  get language(): string {
    return this._language;
  }
  set language(val: string) {
    if (!['nl', 'en'].includes(val)) {
      return;
    }

    this._language = val;

    localStorage.setItem('language', val);

    this.config.notFoundText = this.translate('NOT_FOUND');
    this.config.loadingText = this.translate('LOADING');

    this.document.documentElement.lang = val;
  }

  translations = { nl: null, en: null };

  get locale(): string {
    return { nl: 'nl-NL', en: 'en-US' }[this.language];
  }

  constructor(
    private config: NgSelectConfig,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translations.nl = nl;
    this.translations.en = en;
  }

  translate(key: string): string {
    return this.translations[this.language][key] || key;
  }
}
