<form #form="ngForm" (submit)="login()">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'MEMBER_DATABASE' | translate }}</h4>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td>
          <label for="mail">{{ 'MAIL_ADDRESS' | translate }}</label>
        </td>
        <td>
          <input
            type="email"
            autocomplete="username"
            class="form-control"
            [(ngModel)]="credentials.mail"
            name="mail"
            id="mail"
          />
        </td>
      </tr>
      <tr>
        <td>
          <label for="password">{{ 'PASSWORD' | translate }}</label>
        </td>
        <td>
          <input
            type="password"
            autocomplete="current-password"
            class="form-control"
            [(ngModel)]="credentials.password"
            name="password"
            id="password"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button type="submit" class="btn btn-outline-primary">
      {{ 'LOGIN' | translate }}
    </button>
  </div>
</form>
