import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Membership, MembershipType, Person } from '../all.model';
import { MembershipTypeComponent } from '../membership-type/membership-type.component';
import { MembershipComponent } from '../membership/membership.component';
import { SepaComponent } from '../sepa/sepa.component';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './membership-types.component.html',
})
export class MembershipTypesComponent implements OnInit {
  types: MembershipType[] = [];

  nonMembers: Person[] = [];
  openNonMembers = false;
  expiredMembers: Person[] = [];
  openExpiredMembers = false;

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private modalService: NgbModal,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.translateService.translate('MEMBERSHIPS'));

    this.load();
  }

  private load(): void {
    this.http
      .get('/api/membership-types/extended')
      .subscribe((data: MembershipType[]): void => {
        this.types = data;
      });

    this.http
      .get('/api/membership-type/none')
      .subscribe((data: { none: Person[]; expired: Person[] }): void => {
        this.nonMembers = data.none;
        this.expiredMembers = data.expired;
      });
  }

  edit(event: Event, type: MembershipType = null) {
    event.stopPropagation();

    const modal = this.modalService.open(MembershipTypeComponent);
    modal.componentInstance.id = type ? type.id : null;

    modal.result.then(
      () => this.load(),
      () => {},
    );
  }

  membership(event: Event, member: Membership): void {
    event.stopPropagation();

    const modal = this.modalService.open(MembershipComponent);
    modal.componentInstance.person = member._person;
    modal.componentInstance.id = member.id;

    modal.result.then(
      () => this.load(),
      () => {},
    );
  }

  export(event: Event, type: MembershipType, year: string = '') {
    event.stopPropagation();

    this.http
      .get(`/api/membership-type/${type.id}/export/${year}`, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        this.utility.download(response);
      });
  }

  sepa(): void {
    this.modalService.open(SepaComponent, { size: 'lg' });
  }
}
