import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import {
  Board,
  BoardMember,
  Committee,
  CommitteeMember,
  CommitteeType,
  Membership,
  MembershipType,
  Option,
  Person,
  Sex,
} from '../all.model';
import { BoardMemberComponent } from '../board-member/board-member.component';
import { CommitteeMemberComponent } from '../committee-member/committee-member.component';
import { MembershipComponent } from '../membership/membership.component';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './person.component.html',
  styles: ['.table>tbody>tr>td:first-child { width: 30%; }'],
})
export class PersonComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  Sex: typeof Sex = Sex;

  people: Person[];

  options: Option[];
  membershipTypes = false;
  boards = false;
  committees = false;
  chapters = false;

  person: Person;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title,
    public router: Router,
    private notifier: NotifierService,
    private modalService: NgbModal,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.http.get('/api/persons').subscribe((data: Person[]): void => {
      this.people = data;
    });

    this.http.get('/api/options').subscribe((data: Option[]): void => {
      this.options = data;

      this.route.paramMap.subscribe((paramsMap: ParamMap): void => {
        if (paramsMap.get('id')) {
          if (paramsMap.get('id') === '0') {
            this.form.control.markAsDirty();
            this.person = {
              sex: Sex.f,
              _options: this.options.map((option: Option) => ({
                id: option.id,
                name: option.name,
                value: option.default,
              })),
            } as Person;
            this.titleService.setTitle(
              this.translateService.translate('PERSONS'),
            );
          } else {
            this.load(paramsMap.get('id'));
          }
        } else if (localStorage.getItem('person')) {
          this.router.navigate([`person/${localStorage.getItem('person')}`]);
        } else {
          this.person = null;
          this.titleService.setTitle(
            this.translateService.translate('PERSONS'),
          );
        }
      });
    });

    this.http.get('/api/boards').subscribe((data: Board[]) => {
      this.boards = data.length > 0;
    });

    this.http
      .get('/api/membership-types')
      .subscribe((data: MembershipType[]) => {
        this.membershipTypes = data.length > 0;
      });

    this.http.get('/api/committees').subscribe((data: Committee[]) => {
      this.committees = data.filter(c => c.type === 'committee').length > 0;
      this.chapters = data.filter(c => c.type === 'chapter').length > 0;
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.form.control.dirty) {
      $event.returnValue = true;
    }
  }

  private load(id: number | string): void {
    if (this.form) {
      this.form.control.markAsPristine();
    }

    this.http.get(`/api/person/${id}`).subscribe(
      (data: Person): void => {
        localStorage.setItem('person', id.toString());
        this.person = data;

        this.fixOptions();

        this.titleService.setTitle(
          data ? data.name : this.translateService.translate('PERSONS'),
        );
      },
      (): void => {
        localStorage.removeItem('person');
        this.router.navigate(['person']);
      },
    );
  }

  private fixOptions(): void {
    const options = this.person._options.map(o => o.id);
    this.person._options = this.options.map(
      (option: Option) =>
        ({
          id: option.id,
          name: option.name,
          value: options.includes(option.id),
        } as Option),
    );
  }

  search = (term: string, item: Person): boolean =>
    this.utility.simple(item.name).includes(this.utility.simple(term));

  select(selected: Person): void {
    this.router.navigate([`person/${selected.id}`]);
  }

  save(): void {
    if (!this.form.control.dirty) {
      return;
    }

    this.http
      .post('/api/person', this.person)
      .subscribe((data: Person): void => {
        this.form.control.markAsPristine();
        this.notifier.notify(
          'success',
          this.translateService.translate('SAVED'),
        );
        this.person = data;
        this.fixOptions();
        this.router.navigate([`person/${data.id}`]);
      });
  }

  delete(): void {
    this.utility.delete(this.person.name).subscribe((sure: boolean) => {
      if (sure) {
        this.http
          .delete(`/api/person/${this.person.id}`)
          .subscribe((success: boolean): void => {
            if (success) {
              this.notifier.notify(
                'success',
                `${this.person.name} ${this.translateService.translate(
                  'DELETED',
                )}`,
              );
              this.form.control.markAsPristine();
              localStorage.removeItem('person');
              this.router.navigate(['person']);
            } else {
              this.notifier.notify(
                'error',
                `${this.person.name} ${this.translateService.translate(
                  'NOT_DELETED',
                )} ${this.translateService.translate('MAYBE_USER')}`,
              );
            }
          });
      }
    });
  }

  membership(member: Membership): void {
    if (!this.person.id) {
      return;
    }

    const modal = this.modalService.open(MembershipComponent);
    modal.componentInstance.id = member ? member.id : null;
    modal.componentInstance.person = this.person;

    modal.result.then(
      () => {
        this.load(this.person.id);
      },
      () => {},
    );
  }

  boardMember(event: Event, member: BoardMember): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.person.id) {
      return;
    }

    const modal = this.modalService.open(BoardMemberComponent);
    modal.componentInstance.id = member ? member.id : null;
    modal.componentInstance.person = this.person;

    modal.result.then(
      () => {
        this.load(this.person.id);
      },
      () => {},
    );
  }

  committeeMember(
    event: Event,
    type: CommitteeType,
    member: CommitteeMember,
  ): void {
    event.preventDefault();
    event.stopPropagation();

    if (!this.person.id) {
      return;
    }

    const modal = this.modalService.open(CommitteeMemberComponent);
    modal.componentInstance.id = member ? member.id : null;
    modal.componentInstance.person = this.person;
    modal.componentInstance.type = type;

    modal.result.then(
      () => {
        this.load(this.person.id);
      },
      () => {},
    );
  }

  maps(): void {
    const q = ['address', 'zipcode', 'town', 'country']
      .map(n => this.person[n])
      .filter(x => x)
      .join(', ');
    window.open(`https://maps.google.com/maps?q=${q}`, '_blank');
  }

  membershipYear(membership: Membership): number {
    const years =
      this.string2AcademicYear(membership.deregisterdate) -
      this.string2AcademicYear(membership.registerdate);

    return years + 1;
  }

  membershipYearSup(membership: Membership): string {
    switch (this.membershipYear(membership)) {
      case 1:
        return 'ST';
      case 2:
        return 'ND';
      case 3:
        return 'RD';
      default:
        return 'TH';
    }
  }

  private string2AcademicYear(val: string = null): number {
    const date = val ? new Date(val) : new Date();
    const y = date.getFullYear();

    return date.getMonth() > 7 ? y : y - 1;
  }

  active(date: string): boolean {
    return !date || new Date(date) > new Date();
  }
}
