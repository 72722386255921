export const en = {
  MEMBER_DATABASE: 'Member database',
  MEMBERS: 'Members',
  PERSONS: 'Persons',
  OPTIONS: 'Options',
  MEMBERSHIPS: 'Memberships',
  BOARDS: 'Boards',
  CHAPTERS: 'Chapters',
  COMMITTEES: 'Committees',
  USERS: 'Users',
  QUERIES: 'Queries',
  LOGS: 'Logs',
  SETTINGS: 'Settings',
  INFORMATION: 'Information',
  DASHBOARD_INFO:
    'Here you can see relevant birthdays of the members and some other statistics.',
  PERSONS_INFO:
    'Every member is stored here. A member can be created, changed or deleted from this page. Mail addresses, phone numbers and IBANs are being validated before being saved. Phone numbers must be in international notation. The comments will only be visible when hovering over the text box with your mouse.',
  OPTIONS_INFO:
    'All options you want to check per user can be managed on this page, for example an option for the Newsletter. When creating a new option you can specify whether this option is default on or off. When on, all members will have this option selected and creating a new member will have this option already checked. Each option can be exported to excel.',
  MEMBERSHIPS_INFO:
    'All memberships can be found on this page. The members can be divided in subgroups this way. And it is possible to export these different groups to excel per starting year. Subscription years start on August 1. Members without a membership are at the end of the page. From these memberships it is possible to export a SEPA for collecting the registration fees. To do so, you select the year you want to charge, the date of depreciation and the fee per membership and start year. If this fee is zero, the group will not be included in the SEPA. People without a registration date or valid IBAN will be shown via the yellow triangle in the top right corner.',
  BOARDS_INFO:
    'Here the boards of the association can be managed together with their board members.',
  CHAPTERS_INFO:
    'Here the chapters of the association can be managed together with their members.',
  COMMITTEES_INFO:
    'Here the committees of the association can be managed together with their members.',
  USERS_INFO:
    'Here all users can be found that have access to this database. Their passwords can be changed here as well. When someones account has been blocked due to too many login attempts, this can be fixed here as well.',
  QUERIES_INFO:
    'The raw database can be changed and read from here for very specific actions using SQL. If you do not know what you are doing, you can better leave this page.',
  LOGS_INFO:
    'Every action in the database is saved as a log. These can be inspected here and some actions can be reverted as well, when something went wrong.',
  LANGUAGE: 'Language',
  THEME: 'Theme',
  LIGHT: 'Light',
  DARK: 'Dark',
  YESTERDAY: 'Yesterday',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow',
  BIRTHDAY: ' ',
  MEMBERS_PER_YEAR: 'Members per year',
  MEMBERS_PER_SEX: 'Members per sex',
  MEMBERS_PER_FIRSTNAME: 'Members per first name',
  SAVE: 'Save',
  EDIT: 'Edit',
  ADD: 'Add',
  DELETE: 'Delete',
  NEW_PERSON: 'New person',
  NEW_BOARD: 'New board',
  NEW_COMMITTEE: 'New committee',
  NEW_CHAPTER: 'New chapter',
  NEW_MEMBERSHIPTYPE: 'New membership type',
  NO_MEMBERSHIPTYPES: 'No Membership types found!',
  NO_MEMBERSHIPTYPES_WITH: 'No membership types found for',
  NO_MEMBERSHIPTYPES_WITHOUT: 'Nobody found without a membership!',
  NO_MEMBERSHIP: 'No membership',
  NONE: 'None',
  EXPIRED: 'Expired',
  NEW_OPTION: 'New option',
  DOWNLOAD_SEPA: 'Download SEPA',
  PERSON: 'Person',
  SEARCH_PERSON: 'Search a person...',
  SEARCH_PERSON_ABOVE: 'Look for a person above!',
  MISSING_DATA: 'Missing data',
  MISSES_DATA:
    'misses an IBAN or sign data and can therefore not be included in the SEPA.',
  FIRST_NAME: 'First name',
  INITIALS: 'Initials',
  PREFIX: 'Prefix',
  LAST_NAME: 'Last name',
  SEX: 'Sex',
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
  DATE_OF_BIRTH: 'Date of birth',
  COMMENTS: 'Comments',
  DATA: 'Data',
  MAIL_ADDRESS: 'Mail',
  PHONE_NUMBER: 'Phone',
  IBAN: 'IBAN',
  SIGN_DATE: 'Date of signature',
  ADDRESS: 'Address',
  ZIPCODE: 'Zip code',
  TOWN: 'Town',
  COUNTRY: 'Country',
  ST: 'st',
  ND: 'nd',
  RD: 'rd',
  TH: 'th',
  NO_BOARDS: 'No boards found...',
  SEARCH_BOARD: 'Search a board...',
  SELECT_BOARD: 'Select a board!',
  BOARD: 'Board',
  NAME: 'Name',
  NUMBER: 'Number',
  INSTALLATION: 'Installation',
  DISCHARGE: 'Discharge',
  CREATE_BOARD_MEMBER: 'Create board member',
  CHANGE_BOARD_MEMBER: 'Change board member',
  FUNCTION: 'Function',
  SEARCH_CHAPTER: 'Search a chapter...',
  NO_COMMITTEES: 'No committees found...',
  NO_CHAPTERS: 'No chapters found...',
  COMMITTEE: 'Committee',
  CHAPTER: 'Chapter',
  SEARCH_COMMITTEE: 'Search a committee...',
  SELECT_COMMITTEE: 'Select a committee!',
  SELECT_CHAPTER: 'Select a chapter!',
  CREATE_CHAPTER_MEMBER: 'Create chapter member',
  CHANGE_CHAPTER_MEMBER: 'Change chapter member',
  CREATE_COMMITTEE_MEMBER: 'Create committee member',
  CHANGE_COMMITTEE_MEMBER: 'Change committee member',
  SEARCH_LOG: 'Search for a log in the past half year...',
  CREATION_LOGS: 'Creation logs',
  CHANGE_LOGS: 'Change logs',
  DELETION_LOGS: 'Deletion logs',
  NO_LOGS: 'No logs found!',
  REVERT: 'Revert',
  ATTRIBUTE: 'Attribuut',
  OLD_VALUE: 'Old value',
  NEW_VALUE: 'New value',
  REVERTED: 'Reverted succesfully.',
  NOT_REVERTED: 'It was not possible to revert...',
  TYPE: 'Type',
  SEARCH_TYPE: 'Search a type...',
  REGISTRATION: 'Registration',
  DEREGISTRATION: 'Deregistration',
  CREATE_MEMBERSHIP: 'Create membership',
  CHANGE_MEMBERSHIP: 'Change membership',
  CREATE_OPTION: 'Create option',
  CHANGE_OPTION: 'Change option',
  DEFAULT: 'Default',
  ON: 'On',
  OFF: 'Off',
  NO_OPTIONS: 'No options found!',
  SEARCH_QUERY: 'Search a query...',
  EXECUTE: 'Execute',
  EXPORT: 'Export',
  YEAR: 'Year',
  DEPRECIATION: 'Depreciation',
  COSTS: 'Costs',
  PEOPLE_MISSING_DATA: 'People without signature date or IBAN',
  DOWNLOAD: 'Download',
  YOU_SURE: 'Are you sure?',
  BACK: 'Back',
  CONTINUE: 'Continue',
  NEW_USER: 'New user',
  SEARCH_USER: 'Search a user...',
  NO_USERS: 'No users found...',
  SEARCH_USER_ABOVE: 'Look for a user above!',
  ATTEMPTS: 'Attempts',
  LAST_LOGIN: 'Last login',
  REPEAT_PASSWORD: 'Repeat password',
  NOT_FOUND: 'Not found...',
  LOADING: 'Loading...',
  SAVED: 'Changes have been saved.',
  DELETED: 'has been deleted successfully.',
  NOT_DELETED: 'could not be deleted.',
  MAYBE_USER: 'Maybe he or she is a user?',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  PASSWORD: 'Password',
  USER: 'User',
  SEE_YOU_NEXT_TIME: 'See you next time',
  NOT_LOGGEDIN:
    'The credentials have been tried too many times. Ask an admin for help.',
  CREATE_MEMBERSHIPTYPE: 'Create membership type',
  CHANGE_MEMBERSHIPTYPE: 'Change membership type',
  NO_RESULTS: 'No results.',
  ARE_YOU_SURE_QUERY: 'Are you sure you want to execute this {} query?',
  ONE_RESULT: 'There is one results',
  ANY_RESULTS: 'There are {} results.',
  RESULTS: 'The query was successfully.',
  NO_PASSWORD: 'No password.',
  PASSWORD_TOO_SHORT: 'The password is too short.',
  PASSWORDS_NOT_EQUAL: 'The given passwords are not equal.',
  NOT_ALL_FIELDS: 'Not all fields have been filled out correctly... ',
  SOME_ERROR: 'Someting went wrong on the server...',
  ARE_YOU_SURE_DELETE: 'Are you sure you want to delete {}?',
  UNSAVE: 'There are unsaved changes. Do you want to continue?',
};
