import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { filter, startWith } from 'rxjs/operators';
import { User } from '../all.model';
import { AuthService } from '../auth.service';
import { SettingsComponent } from '../settings/settings.component';
import { TranslateService } from '../translate.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  navbarOpen = false;

  user: User;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    private authService: AuthService,
    private http: HttpClient,
    private translateService: TranslateService,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.navbarOpen = false;
      });

    this.authService.start();

    this.authService.onLogin.pipe(startWith('')).subscribe(() => {
      this.http
        .get('/api/profile')
        .subscribe((user: User) => (this.user = user));
    });
  }

  profile(): void {
    this.modalService.open(SettingsComponent);
  }

  logout(): void {
    this.notifier.notify(
      'info',
      `${this.translateService.translate('SEE_YOU_NEXT_TIME')}, ${
        this.user._person.name
      }`,
    );

    this.user = null;

    this.authService.login();
  }
}
