export interface Person {
  id?: number;
  firstname?: string;
  prefix?: string;
  lastname?: string;
  sex?: Sex;
  birthdate?: string;
  mail?: string;
  phone?: string;
  iban?: string;
  address?: string;
  zipcode?: string;
  town?: string;
  country?: string;
  comments?: string;

  name?: string;
  _membership?: string;
  _memberships?: Membership[];
  _boardMembers?: BoardMember[];
  _committeeMembers?: CommitteeMember[];
  _options?: Option[];
  _user?: boolean | User;
}

export interface Board {
  id?: number;
  name?: string;
  number?: number;
  installationdate?: string;
  dischargedate?: string;
  comments?: string;

  _boardMembers?: BoardMember[];
}

export interface Committee {
  id?: number;
  name?: string;
  type?: CommitteeType;
  comments?: string;

  _committeeMembers?: CommitteeMember[];
}

export interface BoardMember {
  id?: number;
  function?: string;

  _person?: Person;
  _board?: Board;
}

export interface CommitteeMember {
  id?: number;
  function?: string;
  installationdate?: string;
  dischargedate?: string;

  _person?: Person;
  _committee?: Board;
}

export interface Membership {
  id?: number;
  person_id?: number;
  membership_type_id?: number;
  registerdate?: string;
  deregisterdate?: string;

  _person?: Person;
  _membershipType?: MembershipType;
}

export interface MembershipType {
  id?: number;
  name?: string;

  _memberships?: Membership[];
  _years?: {
    year?: string;
    _memberships?: Membership[];
  };

  count?: number;
}

export interface User {
  id?: number;
  name?: string;
  mail: string;
  attempts?: number;
  password: string;
  repeat?: string;

  _person?: Person;
}

export interface Option {
  id?: number;
  name?: string;
  value?: boolean;
  default?: boolean;

  _persons?: Person[];
}

export interface Query {
  id?: number;
  name?: string;
  query?: string;
}

export interface Log {
  id: number;
  change_type: Cud;
  changes: {
    key: string;
    old?: any;
    new?: any;
  }[];
  message: string;
  links: {
    name: string;
    links: string;
  }[];
  revertable: boolean;
  created_at: string;
}

export interface Logs {
  totalCount: number;
  pageCount: number;
  logs: Log[];
}

export enum Sex {
  m = 'm',
  f = 'f',
  o = 'o',
}

export enum CommitteeType {
  committee = 'committee',
  chapter = 'chapter',
}

export enum Cud {
  C = 'create',
  U = 'update',
  D = 'delete',
}
