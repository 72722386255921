import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './sure.component.html',
})
export class SureComponent {
  text: string;
  type = 'danger';

  constructor(private modal: NgbActiveModal) {}

  close(): void {
    this.modal.close(true);
  }

  dismiss(): void {
    this.modal.dismiss();
  }
}
