<div class="modal-header">
  <h4 class="modal-title">
    {{ 'INFORMATION' | translate }}
  </h4>
  <button type="button" class="btn float-right" tabindex="-1" (click)="close()">
    <i class="fa fa-times fa-lg"></i>
  </button>
</div>
<div class="modal-body">
  {{ info | translate }}
</div>
