import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { LoginComponent } from './login/login.component';

@Injectable()
export class AuthService {
  onLogin = new Subject<string>();

  private interval: any;

  get expired(): boolean {
    return (
      new Date().getTime() - Number(localStorage.getItem('expire')) > 890000
    );
  }

  constructor(private modalService: NgbModal) {}

  login(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('expire');
    clearInterval(this.interval);

    this.modalService
      .open(LoginComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      })
      .result.then((token: string) => {
        localStorage.setItem('token', token);
        this.onLogin.next(token);

        this.start();
      });
  }

  start(): void {
    clearInterval(this.interval);

    if (this.expired) {
      this.login();
    } else {
      this.interval = setInterval(() => {
        if (this.expired) {
          clearInterval(this.interval);

          this.login();
        }
      }, 10000);
    }
  }
}
