<form #form="ngForm" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ (new ? 'CREATE_MEMBERSHIP' : 'CHANGE_MEMBERSHIP') | translate }}
    </h4>
    <button
      type="button"
      class="btn float-right"
      tabindex="-1"
      (click)="close()"
    >
      <i class="fa fa-times fa-lg"></i>
    </button>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td>
          <label>{{ 'PERSON' | translate }}</label>
        </td>
        <td>
          <label>{{ person.name }}</label>
        </td>
      </tr>
      <tr>
        <td>
          <label>{{ 'TYPE' | translate }}</label>
        </td>
        <td>
          <ng-select
            [items]="types"
            *ngIf="types"
            [(ngModel)]="member._membershipType"
            bindLabel="name"
            name="membership_type_id"
            [placeholder]="'SEARCH_TYPE' | translate"
            [clearable]="false"
          >
          </ng-select>
        </td>
      </tr>
      <tr>
        <td>
          <label>{{ 'REGISTRATION' | translate }}</label>
        </td>
        <td>
          <app-date-picker
            [(ngModel)]="member.registerdate"
            name="registerdate"
            id="registerdate"
          ></app-date-picker>
        </td>
      </tr>
      <tr>
        <td>
          <label>{{ 'DEREGISTRATION' | translate }}</label>
        </td>
        <td>
          <app-date-picker
            [(ngModel)]="member.deregisterdate"
            name="deregisterdate"
            id="deregisterdate"
          ></app-date-picker>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="delete()"
      *ngIf="!new"
    >
      {{ 'DELETE' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="!form.form.dirty"
    >
      {{ (new ? 'ADD' : 'SAVE') | translate }}
    </button>
  </div>
</form>
