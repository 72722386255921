import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from '../theme.service';
import { TranslateService } from '../translate.service';

@Component({
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  languages = {
    nl: 'Nederlands',
    en: 'English',
  };

  get language(): string {
    return this.translateService.language;
  }
  set language(val: string) {
    this.translateService.language = val;
  }

  themes = {
    light: 'LIGHT',
    dark: 'DARK',
  };

  get theme(): string {
    return this.themeService.theme;
  }
  set theme(val: string) {
    this.themeService.theme = val;
  }

  constructor(
    private modal: NgbActiveModal,
    private translateService: TranslateService,
    private themeService: ThemeService,
  ) {}

  close(): void {
    this.modal.dismiss();
  }
}
