import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEnglish from '@angular/common/locales/en';
import localeDutch from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotifierModule } from 'angular-notifier';
import { CommitteeType } from './all.model';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor } from './auth-http.interceptor';
import { AuthService } from './auth.service';
import { BoardMemberComponent } from './board-member/board-member.component';
import { BoardComponent } from './board/board.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { CommitteeMemberComponent } from './committee-member/committee-member.component';
import { CommitteeComponent } from './committee/committee.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DatePickerComponent } from './date-picker.component';
import { InfoDirective } from './info.directive';
import { InfoComponent } from './info/info.component';
import { LogComponent } from './log/log.component';
import { LoginComponent } from './login/login.component';
import { MembershipTypeComponent } from './membership-type/membership-type.component';
import { MembershipTypesComponent } from './membership-types/membership-types.component';
import { MembershipComponent } from './membership/membership.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OptionComponent } from './option/option.component';
import { OptionsComponent } from './options/options.component';
import { PersonComponent } from './person/person.component';
import { QueryComponent } from './query/query.component';
import { SepaComponent } from './sepa/sepa.component';
import { SettingsComponent } from './settings/settings.component';
import { SortPipe } from './sort.pipe';
import { SureComponent } from './sure/sure.component';
import { TranslatePipe } from './translate.pipe';
import { TranslateService } from './translate.service';
import { UserComponent } from './user/user.component';
import { UtilityService } from './utility.service';

registerLocaleData(localeDutch, 'nl-NL');
registerLocaleData(localeEnglish, 'en-US');

const appRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'person',
    component: PersonComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'person/:id',
    component: PersonComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'board',
    component: BoardComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'board/:id',
    component: BoardComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'committee',
    component: CommitteeComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { type: CommitteeType.committee },
  },
  {
    path: 'committee/:id',
    component: CommitteeComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { type: CommitteeType.committee },
  },
  {
    path: 'chapter',
    component: CommitteeComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { type: CommitteeType.chapter },
  },
  {
    path: 'chapter/:id',
    component: CommitteeComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { type: CommitteeType.chapter },
  },
  {
    path: 'user',
    component: UserComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'user/:id',
    component: UserComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'query',
    component: QueryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'query/:id',
    component: QueryComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'memberships',
    component: MembershipTypesComponent,
  },
  {
    path: 'options',
    component: OptionsComponent,
  },
  {
    path: 'logs',
    component: LogComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  declarations: [
    AppComponent,
    SortPipe,
    TranslatePipe,
    NavbarComponent,
    LoginComponent,
    DashboardComponent,
    PersonComponent,
    BoardComponent,
    UserComponent,
    LogComponent,
    QueryComponent,
    BoardMemberComponent,
    CommitteeMemberComponent,
    MembershipComponent,
    InfoComponent,
    DatePickerComponent,
    CommitteeComponent,
    OptionsComponent,
    OptionComponent,
    MembershipTypesComponent,
    MembershipTypeComponent,
    SepaComponent,
    SureComponent,
    SettingsComponent,
    InfoDirective,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    NotifierModule.withConfig({
      behaviour: {
        autoHide: 2000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4,
      },
    }),
    CodemirrorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useClass: class extends String {
        constructor(protected service: TranslateService) {
          super();
        }

        toString() {
          return this.service.locale;
        }
      },
    },
    CanDeactivateGuard,
    AuthService,
    TranslateService,
    UtilityService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
