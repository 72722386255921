import { Component } from '@angular/core';
import { ThemeService } from './theme.service';
import { TranslateService } from './translate.service';

@Component({
  selector: 'app-root',
  template: `
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <notifier-container></notifier-container>
  `,
})
export class AppComponent {
  constructor(theme: ThemeService, language: TranslateService) {
    theme.theme =
      localStorage.getItem('theme') ||
      (window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
        ? 'dark'
        : null || 'light';
    language.language = localStorage.getItem('language') || 'nl';
  }
}
