import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import 'codemirror/mode/sql/sql.js';
import { of } from 'rxjs';
import { Query } from '../all.model';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './query.component.html',
  styles: ['.card { margin-bottom: -2px; }', '.result { font-size: 85%; }'],
})
export class QueryComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  info: any;

  queries: Query[];

  query: Query;

  results: any;
  error: string;

  codeMirror: any;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    private notifier: NotifierService,
    private titleService: Title,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.query = { name: '', query: '' } as Query;

    this.titleService.setTitle('Queries');

    this.http.get('/api/queries').subscribe((data: Query[]) => {
      this.queries = data;
    });

    this.route.paramMap.subscribe((paramsMap: ParamMap): void => {
      if (paramsMap.get('id')) {
        this.load(paramsMap.get('id'));
      }
    });

    this.codeMirror = {
      lineWrapping: true,
      lineNumbers: true,
      tabSize: 2,
      mode: 'text/x-mysql',
    };

    this.http.get('/api/query/info').subscribe((info: any): void => {
      this.info = info;
    });
  }

  private load(id: number | string): void {
    if (this.form) {
      this.form.control.markAsPristine();
    }

    this.http.get(`/api/query/${id}`).subscribe(
      (data: Query): void => {
        this.query = data;
      },
      (): void => {
        this.router.navigate(['query']);
      },
    );
  }

  select(selected: Query): void {
    this.router.navigate([`query/${selected.id}`]);
  }

  search = (term: string, item: Query): boolean =>
    this.utility.simple(item.name).includes(this.utility.simple(term));

  save(): void {
    if (!this.form.control.dirty) {
      return;
    }

    this.http.post('/api/query', this.query).subscribe((data: Query): void => {
      this.form.control.markAsPristine();
      this.notifier.notify('success', this.translateService.translate('SAVED'));
      this.query = data;
      this.router.navigate([`query/${data.id}`]);
    });
  }

  delete(): void {
    this.utility.delete(this.query.name).subscribe((sure: boolean) => {
      if (sure) {
        this.http
          .delete(`/api/query/${this.query.id}`)
          .subscribe((success: boolean): void => {
            if (success) {
              this.notifier.notify(
                'success',
                `${this.query.name} ${this.translateService.translate(
                  'DELETED',
                )}`,
              );
              this.form.control.markAsPristine();
              this.router.navigate(['query']);
            } else {
              this.notifier.notify(
                'error',
                `${this.query.name} ${this.translateService.translate(
                  'NOT_DELETED',
                )}`,
              );
            }
          });
      }
    });
  }

  try(): void {
    if (!this.query.query) {
      return;
    }

    const queryFirstWord = this.query.query
      .split(' ')
      .filter(w => w !== '')[0]
      .toUpperCase();

    (queryFirstWord === 'SELECT'
      ? of(true)
      : this.utility.confirm(
          this.translateService
            .translate('ARE_YOU_SURE_QUERY')
            .replace('{}', queryFirstWord),
        )
    ).subscribe((r: boolean) => {
      if (r) {
        this.http.post('/api/query/try', { query: this.query.query }).subscribe(
          (result: any[]): void => {
            this.results = { data: result };
            let res = this.translateService.translate('NO_RESULTS');

            if (this.results.data.length > 0) {
              this.results.columns = Object.keys(this.results.data[0]);

              if (this.results.data.length === 1) {
                res = this.translateService.translate('ONE_RESULT');
              } else {
                res = this.translateService
                  .translate('ANY_RESULTS')
                  .replace('{}', this.results.data.length);
              }
            }

            this.notifier.notify(
              'success',
              `${this.translateService.translate('RESULTS')} ${res}`,
            );
            this.error = '';
          },
          (error: HttpErrorResponse): void => {
            if (error.status === 405) {
              let message = error.error.message;
              message = message.substring(message.indexOf(':') + 2);

              const executed = message.indexOf('The SQL being executed was');
              if (executed !== -1) {
                message = message.substring(0, executed);
              }

              this.error = message;
            }
          },
        );
      }
    });
  }

  export(): void {
    this.http
      .get(`/api/query/${this.query.id}/export`, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        this.utility.download(response);
      });
  }

  ctrlEnter(event: KeyboardEvent): void {
    if (event.ctrlKey && event.key === 'Enter') {
      this.try();
    } else {
      this.form.form.markAsDirty();
    }
  }

  checkDirty(event: KeyboardEvent): void {
    if (['Backspace', 'Delete'].includes(event.key)) {
      this.form.form.markAsDirty();
    }
  }
}
