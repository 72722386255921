import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any[], fields: string[]): any[] {
    if (!array || !fields) {
      return;
    }

    for (let i = fields.length - 1; i >= 0; i--) {
      let field = fields[i];

      const fc = field.charAt(0);
      if (fc === '-') {
        field = field.substr(1);
      }

      array.sort((a: any, b: any) => {
        let aField = this.el(a, field);
        let bField = this.el(b, field);

        if (aField !== undefined && bField !== undefined) {
          if (typeof aField === 'string') {
            return aField.localeCompare(bField) * (fc === '-' ? -1 : 1);
          }

          if (typeof aField === 'boolean') {
            aField = aField ? 1 : 0;
            bField = bField ? 1 : 0;
          }

          return (aField - bField) * (fc === '-' ? -1 : 1);
        } else {
          return 0;
        }
      });
    }

    return array;
  }

  private el(object: any, path: string) {
    return path.split('.').reduce((o: any, i: string) => o[i], object);
  }
}
