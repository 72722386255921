<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      <span>{{ 'MEMBER_DATABASE' | translate }}</span>

      <span info="DASHBOARD_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="row">
    <div *ngFor="let day of days; let i = index" class="col-xl-2 col-sm-4">
      <div class="card mb-4">
        <div class="card-header">
          {{ day.name }} {{ 'BIRTHDAY' | translate }}
        </div>
        <div class="list-group list-group-flush">
          <ng-container *ngFor="let birthday of birthdays | sort: ['lastname']">
            <div
              class="list-group-item list-group-item-action pointer"
              *ngIf="birthday.birthday === day.date"
              [routerLink]="['/person', birthday.id]"
              [class.list-group-item-success]="i === 2"
            >
              <span class="float-right">{{ birthday.age }}</span>
              {{ birthday.name }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="card mb-4">
        <div class="card-header">
          <span class="float-right">{{ all }}</span>
          {{ 'MEMBERS_PER_YEAR' | translate }}
        </div>
        <div class="list-group list-group-flush">
          <div
            class="list-group-item"
            *ngFor="let year of years | sort: ['-year']"
          >
            <span class="float-right">{{ year.count }}</span>
            {{ year.year }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card mb-4">
        <div class="card-header">
          <span class="float-right">{{ all }}</span>
          {{ 'MEMBERS_PER_SEX' | translate }}
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item" *ngFor="let s of sex">
            <span class="float-right">{{ s.count }}</span>
            {{ s.sex }}
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card mb-4">
        <div class="card-header">
          {{ 'MEMBERS_PER_FIRSTNAME' | translate }}
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item" *ngFor="let s of firstnames">
            <span class="float-right">{{ s.count }}</span>
            {{ s.firstname }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
