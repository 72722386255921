import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Committee,
  CommitteeMember,
  CommitteeType,
  Person,
} from '../all.model';

@Component({
  templateUrl: './committee-member.component.html',
})
export class CommitteeMemberComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  id: number;
  person: Person;
  committee: Committee;
  type: CommitteeType;

  member: CommitteeMember = {};

  new = false;

  people: Person[] = [];
  committees: Committee[] = [];

  constructor(private http: HttpClient, private modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.id) {
      this.new = true;
      this.member.installationdate = new Date().toJSON().substring(0, 10);
      this.member._person = this.person;
      this.member._committee = this.committee;
    } else {
      this.http
        .get(`/api/committee-member/${this.id}`)
        .subscribe((data: CommitteeMember): void => {
          this.member = data;
        });
    }

    if (!this.person) {
      this.http.get('/api/persons').subscribe((data: Person[]): void => {
        this.people = data;
      });
    }

    if (!this.committee) {
      this.http.get('/api/committees').subscribe((data: Committee[]): void => {
        this.committees = data.filter(
          committee => committee.type === this.type,
        );
      });
    }
  }

  save(): void {
    this.http
      .post('/api/committee-member', this.member)
      .subscribe(this.modal.close);
  }

  delete(): void {
    this.http
      .delete(`/api/committee-member/${this.member.id}`)
      .subscribe(this.modal.close);
  }

  close(): void {
    this.modal.dismiss();
  }
}
