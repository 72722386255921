import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { MembershipType } from '../all.model';

@Component({
  templateUrl: './membership-type.component.html',
})
export class MembershipTypeComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  id: number;

  type: MembershipType = {};

  new = false;

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    private modal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (!this.id) {
      this.new = true;
    } else {
      this.http
        .get(`/api/membership-type/${this.id}`)
        .subscribe((data: MembershipType): void => {
          this.type = data;
        });
    }
  }

  save(): void {
    this.http
      .post('/api/membership-type', this.type)
      .subscribe(this.modal.close);
  }

  delete(): void {
    this.http
      .delete(`/api/membership-type/${this.type.id}`)
      .subscribe((success: boolean) => {
        if (success) {
          this.modal.close();
        } else {
          this.notifier.notify(
            'error',
            'Dit type kon niet worden verwijderd, omdat er nog mensen lid zijn van dit type.',
          );
        }
      });
  }

  close(): void {
    this.modal.dismiss();
  }
}
