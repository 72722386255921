<form #form="ngForm" (submit)="download()">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'DOWNLOAD_SEPA' | translate }}</h4>
    <div>
      <button
        tabindex="-1"
        *ngIf="missingPeople.length"
        type="button"
        class="btn text-warning"
        (click)="missingDataPeople(missingPeopleModal)"
      >
        <i class="fa fa-exclamation-triangle fa-lg"></i>
      </button>
      <button tabindex="-1" type="button" class="btn" (click)="close()">
        <i class="fa fa-times fa-lg"></i>
      </button>
    </div>
  </div>
  <div *ngIf="types" class="overflow-auto">
    <table class="table">
      <tbody>
        <tr>
          <td>
            <label>{{ 'YEAR' | translate }}</label>
          </td>
          <td [colSpan]="yearsLength">
            <ng-select
              [items]="years"
              *ngIf="years"
              [(ngModel)]="year"
              (change)="downloadMissingData()"
              name="year"
              [placeholder]="'YEAR' | translate"
              [clearable]="false"
            >
            </ng-select>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ 'DEPRECIATION' | translate }}</label>
          </td>
          <td [colSpan]="yearsLength">
            <app-date-picker [(ngModel)]="date" name="date"></app-date-picker>
          </td>
        </tr>
        <tr>
          <td>
            <label>{{ 'COSTS' | translate }}</label>
          </td>
          <ng-container *ngFor="let year of years">
            <td *ngIf="showYear(year)">
              <label>{{ year }}</label>
            </td>
          </ng-container>
        </tr>
        <tr *ngFor="let type of types">
          <td class="border-0">
            <label>{{ type.membershipType.name }}</label>
          </td>
          <ng-container *ngFor="let year of years">
            <td class="border-0" *ngIf="showYear(year)">
              <input
                *ngIf="getByYear(type.years, year) > -1; else noYear"
                [(ngModel)]="type.years[getByYear(type.years, year)].cost"
                type="number"
                [name]="type.membershipType.name + year"
                class="form-control"
              />
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button
      type="submit"
      class="btn btn-outline-secondary"
      [disabled]="!form.form.dirty || !date"
    >
      {{ 'DOWNLOAD' | translate }}
    </button>
  </div>
</form>

<ng-template #noYear>
  <input disabled class="form-control" />
</ng-template>

<ng-template #missingPeopleModal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'PEOPLE_MISSING_DATA' | translate }}
    </h4>
  </div>
  <div class="list-group list-group-flush">
    <div *ngFor="let person of missingPeople" class="list-group-item">
      {{ person.name }}
    </div>
  </div>
</ng-template>
