import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '../translate.service';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private titleService: Title,
    private translateService: TranslateService,
  ) {}

  years: any[] = [];
  sex: any[] = [];
  firstnames: any[] = [];
  all: number;

  birthdays: any[];
  days: any[];

  ngOnInit(): void {
    this.titleService.setTitle(
      this.translateService.translate('MEMBER_DATABASE'),
    );

    this.http.get('/api/stats').subscribe((data: any): void => {
      this.years = data.years;
      this.years.sort((a, b) => b.year.localeCompare(a.year));
      this.all = data.years.reduce((p: number, d: any) => d.count + p, 0);

      this.sex = data.sex;

      this.firstnames = data.firstnames;

      this.birthdays = data.birthdays;
    });

    this.days = [
      null,
      this.translateService.translate('YESTERDAY'),
      this.translateService.translate('TODAY'),
      this.translateService.translate('TOMORROW'),
      null,
      null,
    ].map((name, i) => {
      const date = new Date();
      date.setDate(date.getDate() - 2 + i);

      name =
        name ||
        date.toLocaleDateString(this.translateService.locale, {
          weekday: 'long',
        });
      name = name.charAt(0).toUpperCase() + name.slice(1);

      return { name, date: date.toJSON().substring(5, 10) };
    });
  }
}
