<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      {{ 'OPTIONS' | translate }}

      <span info="OPTIONS_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="d-flex">
    <button
      type="button"
      class="btn btn-big btn-outline-primary ml-auto"
      (click)="edit($event)"
    >
      {{ 'NEW_OPTION' | translate }}
    </button>
  </div>

  <div *ngIf="!options.length" class="text-center">
    <p>{{ 'NO_OPTIONS' | translate }}</p>
  </div>

  <div *ngIf="options.length">
    <div *ngFor="let option of options">
      <div class="card mb-2">
        <div
          role="button"
          class="card-header pointer d-flex justify-content-between align-items-center"
          (click)="option.open = !option.open"
        >
          <span>{{ option.name }}</span>
          <div>
            <i>
              {{ 'DEFAULT' | translate }}
              {{ (option.default ? 'ON' : 'OFF') | translate }}
            </i>
            <button
              (click)="export($event, option)"
              class="btn btn-xs btn-outline-secondary ml-3"
              type="button"
            >
              <i class="fa fa-download"></i>
            </button>
            <button
              (click)="edit($event, option)"
              class="btn btn-xs btn-outline-success ml-3"
              type="button"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
        <div class="card-body" *ngIf="option.open">
          <div class="row">
            <span
              class="col-md-2 col-6"
              *ngFor="let person of option._persons | sort: ['lastname']"
              [class.font-italic]="person.sex != 'm'"
            >
              {{ person.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
