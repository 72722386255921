import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  retheme = new Subject<string>();

  private _theme: string = 'light';
  get theme(): string {
    return this._theme;
  }
  set theme(val: string) {
    this._theme = val;

    localStorage.setItem('theme', val);

    this.document.documentElement.setAttribute('theme', val);
  }

  constructor(@Inject(DOCUMENT) private document: Document) {}
}
