import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Person, User } from '../all.model';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './user.component.html',
  styles: ['.table>tbody>tr>td:first-child { width: 30%; }'],
})
export class UserComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  users: User[];
  people: Person[];

  user: User;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title,
    public router: Router,
    private notifier: NotifierService,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.http.get('/api/persons').subscribe((data: Person[]): void => {
      this.people = data;
    });

    this.all();

    this.route.paramMap.subscribe((paramsMap: ParamMap): void => {
      this.utility.showErrors([]);
      if (paramsMap.get('id')) {
        if (paramsMap.get('id') === '0') {
          this.form.control.markAsDirty();
          this.user = {} as User;
          this.titleService.setTitle(this.translateService.translate('USERS'));
        } else {
          this.load(paramsMap.get('id'));
        }
      } else if (localStorage.getItem('user')) {
        this.router.navigate([`user/${localStorage.getItem('user')}`]);
      } else {
        this.user = null;
        this.titleService.setTitle(this.translateService.translate('USERS'));
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.form.control.dirty) {
      $event.returnValue = true;
    }
  }

  private all(): void {
    this.http.get('/api/users').subscribe((data: User[]): void => {
      this.users = data;
    });
  }

  private load(id: number | string): void {
    if (this.form) {
      this.form.control.markAsPristine();
    }

    this.http.get(`/api/user/${id}`).subscribe(
      (data: User): void => {
        localStorage.setItem('user', id.toString());
        this.user = data;
        this.user.repeat = '';
        this.titleService.setTitle(
          data ? data._person.name : this.translateService.translate('USERS'),
        );
        this.all();
      },
      (): void => {
        localStorage.removeItem('user');
        this.router.navigate(['user']);
      },
    );
  }

  search = (term: string, item: User): boolean =>
    this.utility.simple(item.name).includes(this.utility.simple(term));

  save(): void {
    if (!this.form.control.dirty) {
      return;
    }

    if (!this.user.id && !this.user.password) {
      this.notifier.notify(
        'error',
        this.translateService.translate('NO_PASSWORD'),
      );
      return;
    }

    if (this.user.password && this.user.password.length < 8) {
      this.notifier.notify(
        'error',
        this.translateService.translate('PASSWORD_TOO_SHORT'),
      );
      return;
    }

    if (this.user.password && this.user.password !== this.user.repeat) {
      this.notifier.notify(
        'error',
        this.translateService.translate('PASSWORDS_NOT_EQUAL'),
      );
      return;
    }

    this.http.post('/api/user', this.user).subscribe((data: User): void => {
      this.form.control.markAsPristine();
      this.notifier.notify('success', this.translateService.translate('SAVED'));
      this.user = data;
      this.router.navigate([`user/${data.id}`]);
    });
  }

  delete(): void {
    this.utility.delete(this.user._person.name).subscribe((sure: boolean) => {
      if (sure) {
        this.http.delete(`/api/user/${this.user.id}`).subscribe((): void => {
          this.notifier.notify(
            'success',
            `${this.user._person.name} ${this.translateService.translate(
              'DELETED',
            )}`,
          );
          this.form.control.markAsPristine();
          localStorage.removeItem('user');
          this.router.navigate(['user']);
        });
      }
    });
  }
}
