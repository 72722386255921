<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      <span>
        {{ 'BOARDS' | translate }}
        <small *ngIf="board">&nbsp;&raquo; {{ board.name }}</small>
      </span>

      <span info="BOARDS_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="row">
    <div class="col-md-4" *ngIf="boards">
      <div class="card mb-4 d-none d-md-block">
        <div class="list-group list-group-flush">
          <a
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            [class.active]="board && board.id === b.id"
            *ngFor="let b of boards | sort: ['-number']"
            routerLink="/board/{{ b.id }}"
          >
            <span>{{ b.number }}. '{{ b.name }}'</span>
            <span class="font-weight-normal">
              {{ b.boardMembers }}
            </span>
          </a>
          <div class="list-group-item text-center" *ngIf="!boards.length">
            <i>{{ 'NO_BOARDS' | translate }}</i>
          </div>
        </div>
      </div>

      <ng-select
        [items]="boards | sort: ['-number']"
        (change)="router.navigate(['board', $event.id])"
        [placeholder]="'SEARCH_BOARD' | translate"
        class="big-select d-block d-md-none"
        bindLabel="name"
        [clearable]="false"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ item.number }}. '{{ item.name }}'
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.number }}. '{{ item.name }}'
          <span class="float-right font-weight-normal">
            {{ item.boardMembers }}
          </span>
        </ng-template>
      </ng-select>
    </div>

    <div class="col-md-8">
      <form #form="ngForm" (submit)="save()">
        <div>
          <button
            class="btn btn-big btn-outline-success mr-2"
            [disabled]="!board || !form.form.dirty"
            type="submit"
          >
            <span class="d-none d-sm-block">{{ 'SAVE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-pencil fa-lg"></i>
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-danger"
            (click)="delete()"
            [disabled]="!board || !board.id || form.form.dirty"
          >
            <span class="d-none d-sm-block">{{ 'DELETE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-times fa-lg"></i>
          </button>
          <button
            class="btn btn-big btn-outline-primary float-right"
            routerLink="/board/0"
            [disabled]="form.form.dirty"
          >
            <span class="d-none d-sm-block">{{ 'NEW_BOARD' | translate }}</span>
            <i class="d-block d-sm-none fa fa-plus fa-lg"></i>
          </button>
        </div>
        <div class="text-center" *ngIf="!board">
          <p>{{ 'SELECT_BOARD' | translate }}</p>
        </div>

        <div *ngIf="board">
          <div class="row">
            <div class="col-lg-6">
              <div class="card mb-4">
                <div class="card-header">{{ 'BOARD' | translate }}</div>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <label for="name">{{ 'NAME' | translate }}</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          [(ngModel)]="board.name"
                          name="name"
                          id="name"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="number">{{ 'NUMBER' | translate }}</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          [(ngModel)]="board.number"
                          name="number"
                          id="number"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="installationdate">
                          {{ 'INSTALLATION' | translate }}
                        </label>
                      </td>
                      <td>
                        <app-date-picker
                          [(ngModel)]="board.installationdate"
                          name="installationdate"
                          id="installationdate"
                        >
                        </app-date-picker>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="dischargedate">
                          {{ 'DISCHARGE' | translate }}
                        </label>
                      </td>
                      <td>
                        <app-date-picker
                          [(ngModel)]="board.dischargedate"
                          name="dischargedate"
                          id="dischargedate"
                        >
                        </app-date-picker>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card mb-4">
                <div class="card-header">{{ 'COMMENTS' | translate }}</div>
                <textarea
                  class="form-control form-comments"
                  [(ngModel)]="board.comments"
                  name="comments"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card mb-4">
                <div
                  class="card-header d-flex justify-content-between align-items-center"
                >
                  <span>{{ 'MEMBERS' | translate }}</span>
                  <button
                    (click)="boardMember()"
                    class="btn btn-xs btn-outline-primary"
                    type="button"
                    [disabled]="!board.id"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div class="list-group list-group-flush">
                  <a
                    routerLink="/person/{{ b._person.id }}"
                    class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    *ngFor="let b of board._boardMembers"
                  >
                    <span>{{ b._person.name }}</span>
                    <div>
                      <i>{{ b.function }}</i>
                      <button
                        (click)="boardMember(b)"
                        class="btn btn-xs btn-outline-success ml-3"
                        type="button"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
