<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      {{ 'MEMBERSHIPS' | translate }}

      <span info="MEMBERSHIPS_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="d-flex justify-content-between">
    <button
      type="button"
      class="btn btn-big btn-outline-secondary"
      (click)="sepa()"
    >
      {{ 'DOWNLOAD_SEPA' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-big btn-outline-primary"
      (click)="edit($event)"
    >
      {{ 'NEW_MEMBERSHIPTYPE' | translate }}
    </button>
  </div>

  <div *ngIf="!types.length" class="text-center">
    <p>{{ 'NO_MEMBERSHIPTYPES' | translate }}</p>
  </div>

  <div *ngIf="types.length">
    <div *ngFor="let type of types">
      <div class="d-flex justify-content-between">
        <h3>
          {{ type.name }}
        </h3>
        <div class="d-flex justify-content-end">
          <h3 class="text-secondary mr-3">{{ type.count }}</h3>
          <button
            type="button"
            class="btn btn-big btn-outline-secondary mr-2"
            (click)="export($event, type)"
          >
            <i class="fa fa-download fa-lg"></i>
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-success"
            (click)="edit($event, type)"
          >
            <span class="d-none d-sm-block">{{ 'EDIT' | translate }}</span>
            <i class="d-block d-sm-none fa fa-pencil fa-lg"></i>
          </button>
        </div>
      </div>
      <div *ngIf="!type._years.length" class="text-center">
        <p>{{ 'NO_MEMBERSHIPTYPES_WITH' | translate }} {{ type.name }}!</p>
      </div>
      <div class="row mb-3" *ngIf="type._years.length">
        <div
          class="col-md-3"
          *ngFor="let year of type._years | sort: ['-year']"
        >
          <div class="card mb-2">
            <div
              role="button"
              class="card-header pointer d-flex justify-content-between align-items-center"
              (click)="year.open = !year.open"
            >
              <span>{{ year.year }}</span>
              <div class="ml-3">
                <span class="text-secondary">
                  {{ year._memberships.length }}
                </span>
                <button
                  (click)="export($event, type, year.year)"
                  class="btn btn-xs btn-outline-secondary ml-2"
                  type="button"
                >
                  <i class="fa fa-download"></i>
                </button>
              </div>
            </div>
            <div class="list-group list-group-flush" *ngIf="year.open">
              <div
                class="list-group-item list-group-item-action pointer d-flex justify-content-between align-items-center"
                *ngFor="let m of year._memberships | sort: ['_person.lastname']"
                [routerLink]="['/person', m._person.id]"
              >
                <span>{{ m._person.name }}</span>
                <button
                  (click)="membership($event, m)"
                  class="btn btn-xs btn-outline-success"
                  type="button"
                >
                  <i class="fa fa-pencil"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="d-flex justify-content-between" [style.min-height.px]="50">
        <h3>{{ 'NO_MEMBERSHIP' | translate }}</h3>
      </div>
      <div
        *ngIf="!nonMembers.length && !expiredMembers.length"
        class="text-center"
      >
        <p>{{ 'NO_MEMBERSHIPTYPES_WITHOUT' | translate }}</p>
      </div>
      <div class="row mb-3">
        <div class="col-md-3" *ngIf="nonMembers.length">
          <div class="card mb-2">
            <div
              role="button"
              class="card-header pointer d-flex justify-content-between align-items-center"
              (click)="openNonMembers = !openNonMembers"
            >
              <span>{{ 'NONE' | translate }}</span>
              <span class="text-secondary">{{ nonMembers.length }}</span>
            </div>
            <div class="list-group list-group-flush" *ngIf="openNonMembers">
              <div
                class="list-group-item list-group-item-action pointer"
                *ngFor="let p of nonMembers | sort: ['lastname']"
                [routerLink]="['/person', p.id]"
              >
                {{ p.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3" *ngIf="expiredMembers.length">
          <div class="card mb-2">
            <div
              role="button"
              class="card-header pointer d-flex justify-content-between align-items-center"
              (click)="openExpiredMembers = !openExpiredMembers"
            >
              <span>{{ 'EXPIRED' | translate }}</span>
              <span class="text-secondary">{{ expiredMembers.length }}</span>
            </div>
            <div class="list-group list-group-flush" *ngIf="openExpiredMembers">
              <div
                class="list-group-item list-group-item-action pointer"
                *ngFor="let p of expiredMembers | sort: ['lastname']"
                [routerLink]="['/person', p.id]"
              >
                {{ p.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
