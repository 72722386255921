import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Option } from '../all.model';
import { OptionComponent } from '../option/option.component';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './options.component.html',
})
export class OptionsComponent implements OnInit {
  options: Option[] = [];

  constructor(
    private http: HttpClient,
    private titleService: Title,
    private modalService: NgbModal,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.translateService.translate('OPTIONS'));

    this.load();
  }

  private load(): void {
    this.http.get('/api/options').subscribe((data: Option[]): void => {
      this.options = data;
    });
  }

  edit(event: Event, option: Option = null) {
    event.stopPropagation();

    const modal = this.modalService.open(OptionComponent);
    modal.componentInstance.id = option ? option.id : null;

    modal.result.then(
      () => this.load(),
      () => {},
    );
  }

  export(event: Event, option: Option) {
    event.stopPropagation();

    this.http
      .get(`/api/option/${option.id}/export`, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        this.utility.download(response);
      });
  }
}
