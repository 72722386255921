<form #form="ngForm" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ (new ? 'CREATE_BOARD_MEMBER' : 'CHANGE_BOARD_MEMBER') | translate }}
    </h4>
    <button
      type="button"
      class="btn float-right"
      tabindex="-1"
      (click)="close()"
    >
      <i class="fa fa-times fa-lg"></i>
    </button>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td>
          <label>{{ 'PERSON' | translate }}</label>
        </td>
        <td *ngIf="!person">
          <ng-select
            [items]="people | sort: ['lastname']"
            *ngIf="people"
            [(ngModel)]="member._person"
            name="person_id"
            bindLabel="name"
            [placeholder]="'SEARCH_PERSON' | translate"
            [clearable]="false"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span [class.font-italic]="item.sex != 'm'">
                {{ item.name }}
              </span>
            </ng-template>
          </ng-select>
        </td>
        <td *ngIf="person">
          <label>{{ person.name }}</label>
        </td>
      </tr>
      <tr>
        <td>
          <label>{{ 'BOARD' | translate }}</label>
        </td>
        <td *ngIf="!board">
          <ng-select
            [items]="boards | sort: ['-number']"
            *ngIf="boards"
            [(ngModel)]="member._board"
            name="board_id"
            [placeholder]="'SEARCH_BOARD' | translate"
            [clearable]="false"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.number }}. '{{ item.name }}'
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ item.number }}. '{{ item.name }}'
              <span class="float-right font-weight-normal">
                {{ item.boardMembers }}
              </span>
            </ng-template>
          </ng-select>
        </td>
        <td *ngIf="board">
          <label>{{ board.number }}. '{{ board.name }}'</label>
        </td>
      </tr>
      <tr>
        <td>
          <label for="function">{{ 'FUNCTION' | translate }}</label>
        </td>
        <td>
          <input
            type="text"
            [(ngModel)]="member.function"
            name="function"
            id="function"
            class="form-control"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="delete()"
      *ngIf="!new"
    >
      {{ 'DELETE' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="!form.form.dirty"
    >
      {{ (new ? 'ADD' : 'SAVE') | translate }}
    </button>
  </div>
</form>
