<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      {{ 'LOGS' | translate }}

      <span info="LOGS_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="input-group mb-4">
    <input
      [(ngModel)]="searchFilter"
      class="form-control form-control-bg"
      [placeholder]="'SEARCH_LOG' | translate"
      (ngModelChange)="search.next($event)"
    />
    <div class="input-group-append">
      <button
        type="button"
        class="btn btn-outline-secondary btn-form"
        (click)="search.next('')"
      >
        <i class="fa fa-times px-1"></i>
      </button>
    </div>
  </div>
  <div class="btn-group d-none d-sm-flex mb-4">
    <button
      type="button"
      class="btn btn-outline-secondary w-100"
      [class.active]="cudFilter == Cud.C"
      (click)="setFilter(Cud.C)"
    >
      <i class="fa fa-plus"></i> {{ 'CREATION_LOGS' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary w-100"
      [class.active]="cudFilter == Cud.U"
      (click)="setFilter(Cud.U)"
    >
      <i class="fa fa-pencil"></i> {{ 'CHANGE_LOGS' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary w-100"
      [class.active]="cudFilter == Cud.D"
      (click)="setFilter(Cud.D)"
    >
      <i class="fa fa-times"></i> {{ 'DELETION_LOGS' | translate }}
    </button>
  </div>
  <div class="text-center" *ngIf="!amountLogs">
    <p>{{ 'NO_LOGS' | translate }}</p>
  </div>
  <ng-template [ngIf]="amountLogs">
    <div *ngFor="let log of logs">
      <div class="card mb-2">
        <div class="card-header pointer" (click)="log.open = !log.open">
          <div class="float-right">
            {{ log.created_at + ' UTC' | date: 'd MMMM y H:mm' }}
          </div>
          <div class="float-left mr-3">
            <i *ngIf="log.change_type == 'create'" class="fa fa-plus"></i>
            <i *ngIf="log.change_type == 'update'" class="fa fa-pencil"></i>
            <i *ngIf="log.change_type == 'delete'" class="fa fa-times"></i>
          </div>
          {{ log.message }}
        </div>
        <div class="card-body" *ngIf="log.open">
          <div class="btn-group float-right">
            <button
              type="button"
              *ngFor="let link of log.links"
              routerLink="/{{ link.link }}"
              class="btn btn-outline-secondary"
            >
              <i class="fa fa-tag"></i> {{ link.name }}
            </button>
            <button
              type="button"
              (click)="revert(log)"
              class="btn btn-outline-secondary"
              [disabled]="!log.revertable"
            >
              <i class="fa fa-undo"></i> {{ 'REVERT' | translate }}
            </button>
          </div>
        </div>
        <table class="table" *ngIf="log.open">
          <thead>
            <tr>
              <th>{{ 'ATTRIBUTE' | translate }}</th>
              <th *ngIf="log.change_type != 'create'">
                {{ 'OLD_VALUE' | translate }}
              </th>
              <th *ngIf="log.change_type != 'delete'">
                {{ 'NEW_VALUE' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let attribute of log.changes">
              <td>{{ attribute.label }}</td>
              <td
                *ngIf="log.change_type !== Cud.C"
                [innerHTML]="attribute.old"
              ></td>
              <td
                *ngIf="log.change_type !== Cud.D"
                [innerHTML]="attribute.new"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>

  <div
    *ngIf="pages && amountLogs && pages.length > 1"
    class="mt-3 d-flex justify-content-center"
  >
    <div class="btn-group">
      <button
        class="btn btn-outline-secondary"
        (click)="load(currentPage - 1)"
        *ngIf="currentPage !== 1"
      >
        &laquo;
      </button>
      <button
        *ngFor="let page of pages"
        class="btn btn-outline-secondary d-none d-sm-block"
        [class.active]="currentPage === page"
        (click)="load(page)"
      >
        {{ page }}
      </button>
      <button
        class="btn btn-outline-secondary"
        (click)="load(currentPage + 1)"
        *ngIf="currentPage !== totalPages"
      >
        &raquo;
      </button>
    </div>
  </div>
</div>
