import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { from, Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TranslateService } from './translate.service';
import { UtilityService } from './utility.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private notifier: NotifierService,
    private utility: UtilityService,
    private authService: AuthService,
    private translateService: TranslateService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return (!this.authService.expired || request.url === '/api/login'
      ? from([localStorage.getItem('token')])
      : this.authService.onLogin
    ).pipe(
      first(),
      switchMap((token: string) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: token,
              Language: this.translateService.locale || 'nl-NL',
            },
          });
        }

        return next.handle(request).pipe(
          tap(
            (): void => {
              this.utility.showErrors([]);

              localStorage.setItem('expire', new Date().getTime().toFixed());
            },
            (err: any): void => {
              if (err instanceof HttpErrorResponse) {
                switch (err.status) {
                  case 401:
                    this.authService.login();
                    break;
                  case 404:
                  case 405:
                    break;
                  case 422:
                    const errors = JSON.parse(err.error.message);
                    this.notifier.notify(
                      'error',
                      this.translateService.translate('NOT_ALL_FIELDS') +
                        Object.values(errors).join(' '),
                    );
                    this.utility.showErrors(Object.keys(errors));
                    break;
                  default:
                    this.notifier.notify(
                      'error',
                      this.translateService.translate('SOME_ERROR'),
                    );
                }
              }
            },
          ),
        );
      }),
    );
  }
}
