<nav class="navbar navbar-expand-lg sticky-top">
  <div class="container px-0 px-sm-3">
    <a class="navbar-brand" routerLink="/dashboard">
      {{ 'MEMBERS' | translate }}
    </a>

    <button
      class="navbar-toggler pr-0"
      type="button"
      (click)="navbarOpen = !navbarOpen"
    >
      <i class="fa fa-bars fa-lg" *ngIf="!navbarOpen"></i>
      <i class="fa fa-times fa-lg" *ngIf="navbarOpen"></i>
    </button>

    <div class="collapse navbar-collapse" [class.show]="navbarOpen">
      <ul class="navbar-nav mr-auto">
        <a class="nav-link" routerLink="/person" routerLinkActive="active">
          {{ 'PERSONS' | translate }}
        </a>
        <a class="nav-link" routerLink="/options" routerLinkActive="active">
          {{ 'OPTIONS' | translate }}
        </a>
        <a class="nav-link" routerLink="/memberships" routerLinkActive="active">
          {{ 'MEMBERSHIPS' | translate }}
        </a>
        <a class="nav-link" routerLink="/board" routerLinkActive="active">
          {{ 'BOARDS' | translate }}
        </a>
        <a class="nav-link" routerLink="/chapter" routerLinkActive="active">
          {{ 'CHAPTERS' | translate }}
        </a>
        <a class="nav-link" routerLink="/committee" routerLinkActive="active">
          {{ 'COMMITTEES' | translate }}
        </a>
        <a class="nav-link" routerLink="/user" routerLinkActive="active">
          {{ 'USERS' | translate }}
        </a>
        <a class="nav-link" routerLink="/query" routerLinkActive="active">
          {{ 'QUERIES' | translate }}
        </a>
        <a class="nav-link" routerLink="/logs" routerLinkActive="active">
          {{ 'LOGS' | translate }}
        </a>
      </ul>

      <a class="nav-profile mr-3" (click)="profile()">
        {{ user?._person?.name }}
      </a>
      <button
        type="button"
        class="btn btn-outline-secondary float-right"
        [disabled]="!user"
        (click)="logout()"
      >
        <span class="d-block d-lg-none d-xl-block">
          {{ 'LOGOUT' | translate }}
        </span>
        <i class="d-none d-lg-block d-xl-none fa fa-sign-out fa-lg"></i>
      </button>
    </div>
  </div>
</nav>
