<div class="modal-header">
  <h4 class="modal-title">
    {{ 'SETTINGS' | translate }}
  </h4>
  <button type="button" class="btn float-right" tabindex="-1" (click)="close()">
    <i class="fa fa-times fa-lg"></i>
  </button>
</div>
<table class="table">
  <tbody>
    <tr>
      <td>
        <label>{{ 'LANGUAGE' | translate }}</label>
      </td>
      <td>
        <ng-select
          [items]="languages | keyvalue"
          bindLabel="value"
          bindValue="key"
          [(ngModel)]="language"
          [clearable]="false"
        >
        </ng-select>
      </td>
    </tr>
    <tr>
      <td>
        <label>{{ 'THEME' | translate }}</label>
      </td>
      <td>
        <ng-select
          [items]="themes | keyvalue"
          bindValue="key"
          [(ngModel)]="theme"
          [clearable]="false"
        >
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            {{ item.value | translate }}
          </ng-template>
        </ng-select>
      </td>
    </tr>
  </tbody>
</table>
