<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      <span>
        {{ 'PERSONS' | translate }}
        <small *ngIf="person">&nbsp;&raquo; {{ person.name }}</small>
      </span>

      <span info="PERSONS_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <ng-select
    [items]="people | sort: ['lastname']"
    (change)="router.navigate(['person/' + $event.id])"
    [placeholder]="'SEARCH_PERSON' | translate"
    [clearable]="false"
    [searchFn]="search"
    class="big-select"
    [virtualScroll]="true"
  >
    <ng-template ng-label-tmp let-item="item">
      {{ item.name }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      <span [class.font-italic]="item.sex != 'm'">
        {{ item.name }}
      </span>
    </ng-template>
  </ng-select>

  <form #form="ngForm" (submit)="save()">
    <div>
      <button
        class="btn btn-big btn-outline-success mr-2"
        [disabled]="!person || !form.form.dirty"
        type="submit"
      >
        <span class="d-none d-sm-block">{{ 'SAVE' | translate }}</span>
        <i class="d-block d-sm-none fa fa-pencil fa-lg"></i>
      </button>
      <button
        type="button"
        class="btn btn-big btn-outline-danger"
        (click)="delete()"
        [disabled]="!person || !person.id || form.form.dirty"
      >
        <span class="d-none d-sm-block">{{ 'DELETE' | translate }}</span>
        <i class="d-block d-sm-none fa fa-times fa-lg"></i>
      </button>

      <button
        type="button"
        class="btn btn-big btn-outline-primary float-right"
        routerLink="/person/0"
        [disabled]="form.form.dirty"
      >
        <span class="d-none d-sm-block">{{ 'NEW_PERSON' | translate }}</span>
        <i class="d-block d-sm-none fa fa-plus fa-lg"></i>
      </button>
    </div>
    <div class="text-center" *ngIf="!person">
      <p>{{ 'SEARCH_PERSON_ABOVE' | translate }}</p>
    </div>

    <div *ngIf="person">
      <div class="row">
        <div class="col-lg-6 col-xl-4">
          <div
            class="card bg-warning mb-4"
            *ngIf="person.id && (!person.signdate || !person.iban)"
          >
            <div class="card-header">{{ 'MISSING_DATA' | translate }}</div>

            <div class="card-body text-body">
              {{ person.firstname }} {{ 'MISSES_DATA' | translate }}
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header">{{ 'PERSON' | translate }}</div>
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <label for="firstname">
                      {{ 'FIRST_NAME' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.firstname"
                      name="firstname"
                      id="firstname"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="initials">
                      {{ 'INITIALS' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.initials"
                      name="initials"
                      id="initials"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="prefix">
                      {{ 'PREFIX' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.prefix"
                      name="prefix"
                      id="prefix"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="lastname">
                      {{ 'LAST_NAME' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.lastname"
                      name="lastname"
                      id="lastname"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label>
                      {{ 'SEX' | translate }}
                    </label>
                  </td>
                  <td>
                    <div class="row">
                      <div class="col-4" [title]="'MALE' | translate">
                        <input
                          type="radio"
                          [(ngModel)]="person.sex"
                          name="sex"
                          id="m"
                          [value]="Sex.m"
                        />
                        <label for="m">
                          <i class="fa fa-mars"></i>
                        </label>
                      </div>
                      <div class="col-4" [title]="'FEMALE' | translate">
                        <input
                          type="radio"
                          [(ngModel)]="person.sex"
                          name="sex"
                          id="f"
                          [value]="Sex.f"
                        />
                        <label for="f">
                          <i class="fa fa-venus"></i>
                        </label>
                      </div>
                      <div class="col-4" [title]="'OTHER' | translate">
                        <input
                          type="radio"
                          [(ngModel)]="person.sex"
                          name="sex"
                          id="o"
                          [value]="Sex.o"
                        />
                        <label for="o">
                          <i class="fa fa-genderless"></i>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="birthdate">
                      {{ 'DATE_OF_BIRTH' | translate }}
                    </label>
                  </td>
                  <td>
                    <app-date-picker
                      [(ngModel)]="person.birthdate"
                      name="birthdate"
                      id="birthdate"
                    >
                    </app-date-picker>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card mb-4" *ngIf="options.length">
            <div class="card-header">{{ 'OPTIONS' | translate }}</div>
            <div class="row mx-3 my-2">
              <div class="col-6 px-0" *ngFor="let option of person._options">
                <input
                  type="checkbox"
                  [(ngModel)]="option.value"
                  [name]="option.name"
                  [id]="option.id"
                />
                <label [for]="option.id">{{ option.name }}</label>
              </div>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header">
              {{ 'COMMENTS' | translate }}
            </div>
            <textarea
              class="form-control form-comments secret"
              [(ngModel)]="person.comments"
              name="comments"
            ></textarea>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4">
          <div class="card mb-4">
            <div class="card-header">
              {{ 'DATA' | translate }}
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <label for="mail">
                      {{ 'MAIL_ADDRESS' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="mail"
                      [(ngModel)]="person.mail"
                      id="email"
                      name="email"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="phone">
                      {{ 'PHONE_NUMBER' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.phone"
                      id="phone"
                      name="phone"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="iban">
                      {{ 'IBAN' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.iban"
                      id="iban"
                      name="iban"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="signdate">
                      {{ 'SIGN_DATE' | translate }}
                    </label>
                  </td>
                  <td>
                    <app-date-picker
                      [(ngModel)]="person.signdate"
                      name="signdate"
                      id="signdate"
                    >
                    </app-date-picker>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card mb-4">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span>{{ 'ADDRESS' | translate }}</span>
              <div>
                <button
                  (click)="maps()"
                  class="btn btn-xs btn-outline-secondary"
                  type="button"
                >
                  <i class="fa fa-map"></i>
                </button>
              </div>
            </div>
            <table class="table">
              <tbody>
                <tr>
                  <td>
                    <label for="address">
                      {{ 'ADDRESS' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.address"
                      id="address"
                      name="address"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="zipcode">
                      {{ 'ZIPCODE' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.zipcode"
                      id="zipcode"
                      name="zipcode"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="town">
                      {{ 'TOWN' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.town"
                      id="town"
                      name="town"
                      class="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="country">
                      {{ 'COUNTRY' | translate }}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      [(ngModel)]="person.country"
                      id="country"
                      name="country"
                      class="form-control"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-6 col-xl-4">
          <div class="card mb-4" *ngIf="membershipTypes">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span>{{ 'MEMBERSHIPS' | translate }}</span>
              <button
                (click)="membership()"
                class="btn btn-xs btn-outline-primary"
                type="button"
                [disabled]="!person.id"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="list-group list-group-flush">
              <div
                class="list-group-item d-flex justify-content-between align-items-center"
                [class.list-group-item-success]="active(m.deregisterdate)"
                *ngFor="let m of person._memberships"
              >
                <span>{{ m._membershipType.name }}</span>
                <div>
                  <i>
                    {{ membershipYear(m)
                    }}<sup>{{ membershipYearSup(m) | translate }}</sup>
                    {{ 'YEAR' | translate: true }}
                  </i>
                  <button
                    (click)="membership(m)"
                    class="btn btn-xs btn-outline-success ml-3"
                    type="button"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-4" *ngIf="boards">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span>{{ 'BOARDS' | translate }}</span>
              <button
                (click)="boardMember($event)"
                class="btn btn-xs btn-outline-primary"
                type="button"
                [disabled]="!person.id"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="list-group list-group-flush">
              <a
                routerLink="/board/{{ b._board.id }}"
                class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                [class.list-group-item-success]="active(b._board.dischargedate)"
                *ngFor="let b of person._boardMembers"
              >
                <span>{{ b._board.number }}. {{ b._board.name }}</span>
                <div>
                  <i>{{ b.function }}</i>
                  <button
                    (click)="boardMember($event, b)"
                    class="btn btn-xs btn-outline-success ml-3"
                    type="button"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                </div>
              </a>
            </div>
          </div>

          <div class="card mb-4" *ngIf="chapters">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span>{{ 'CHAPTERS' | translate }}</span>
              <button
                (click)="committeeMember($event, 'chapter')"
                class="btn btn-xs btn-outline-primary ml-3"
                type="button"
                [disabled]="!person.id"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="list-group list-group-flush">
              <ng-container *ngFor="let c of person._committeeMembers">
                <a
                  routerLink="/committee/{{ c._committee.id }}"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  [class.list-group-item-success]="active(c.dischargedate)"
                  *ngIf="c._committee.type == 'chapter'"
                >
                  <span>{{ c._committee.name }}</span>
                  <div>
                    <i>{{ c.function }}</i>
                    <button
                      (click)="committeeMember($event, 'chapter', c)"
                      class="btn btn-xs btn-outline-success ml-3"
                      type="button"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </a>
              </ng-container>
            </div>
          </div>

          <div class="card mb-4" *ngIf="committees">
            <div
              class="card-header d-flex justify-content-between align-items-center"
            >
              <span>{{ 'COMMITTEES' | translate }}</span>
              <button
                (click)="committeeMember($event, 'committee')"
                class="btn btn-xs btn-outline-primary"
                type="button"
                [disabled]="!person.id"
              >
                <i class="fa fa-plus"></i>
              </button>
            </div>
            <div class="list-group list-group-flush">
              <ng-container *ngFor="let c of person._committeeMembers">
                <a
                  routerLink="/committee/{{ c._committee.id }}"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  [class.list-group-item-success]="active(c.dischargedate)"
                  *ngIf="c._committee.type == 'committee'"
                >
                  <span>{{ c._committee.name }}</span>
                  <div>
                    <i>{{ c.function }}</i>
                    <button
                      (click)="committeeMember($event, 'committee', c)"
                      class="btn btn-xs btn-outline-success ml-3"
                      type="button"
                    >
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
