<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      <span>
        {{ 'USERS' | translate }}
        <small *ngIf="user?._person">
          &nbsp;&raquo; {{ user._person?.name }}
        </small>
      </span>

      <span info="USERS_INFO"><i class="fa fa-info-circle"></i></span>
    </h2>
  </div>

  <div class="row">
    <div class="col-md-4" *ngIf="users">
      <div class="card mb-4 d-none d-md-block">
        <div class="list-group list-group-flush">
          <a
            class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
            [class.active]="user && user.id === u.id"
            *ngFor="let u of users"
            [routerLink]="['/user', u.id]"
          >
            {{ u._person.name }}
          </a>
          <div class="list-group-item text-center" *ngIf="!users.length">
            <i>{{ 'NO_USERS' | translate }}</i>
          </div>
        </div>
      </div>

      <ng-select
        [items]="users"
        (change)="router.navigate(['/user', $event.id])"
        [placeholder]="'SEARCH_USER' | translate"
        class="big-select d-block d-md-none"
        bindLabel="name"
        [clearable]="false"
      >
        <ng-template ng-label-tmp ng-option-tmp let-item="item">
          {{ item._person.name }}
        </ng-template>
      </ng-select>
    </div>

    <div class="col-md-8">
      <form #form="ngForm" (submit)="save()">
        <div>
          <button
            class="btn btn-big btn-outline-success mr-2"
            [disabled]="!user || !form.form.dirty"
            type="submit"
          >
            <span class="d-none d-sm-block">{{ 'SAVE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-pencil fa-lg"></i>
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-danger"
            (click)="delete()"
            [disabled]="!user || !user.id || form.form.dirty"
          >
            <span class="d-none d-sm-block">{{ 'DELETE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-times fa-lg"></i>
          </button>

          <button
            type="button"
            class="btn btn-big btn-outline-primary float-right"
            routerLink="/user/0"
            [disabled]="form.form.dirty"
          >
            <span class="d-none d-sm-block">{{ 'NEW_USER' | translate }}</span>
            <i class="d-block d-sm-none fa fa-plus fa-lg"></i>
          </button>
        </div>
        <div class="text-center" *ngIf="!user">
          <p>{{ 'SEARCH_USER_ABOVE' | translate }}</p>
        </div>

        <div *ngIf="user">
          <div class="row">
            <div class="col-lg-6">
              <div class="card mb-4">
                <div class="card-header">{{ 'USER' | translate }}</div>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <label for="mail">{{
                          'MAIL_ADDRESS' | translate
                        }}</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          [(ngModel)]="user.mail"
                          id="mail"
                          name="mail"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label>{{ 'PERSON' | translate }}</label>
                      </td>
                      <td>
                        <ng-select
                          [items]="people | sort: ['lastname']"
                          *ngIf="people"
                          [(ngModel)]="user._person"
                          name="person_id"
                          bindLabel="name"
                          [placeholder]="'PERSON' | translate"
                          [clearable]="false"
                        >
                        </ng-select>
                      </td>
                    </tr>
                    <tr *ngIf="user.attempts">
                      <td>
                        <label>{{ 'ATTEMPTS' | translate }}</label>
                      </td>
                      <td>
                        <div class="float-right">
                          <button
                            (click)="
                              user.attempts = 0; form.control.markAsDirty()
                            "
                            class="btn btn-outline-secondary btn-form"
                            type="button"
                          >
                            <i class="fa fa-unlock"></i>
                          </button>
                        </div>
                        {{ user.attempts }} / 5
                      </td>
                    </tr>
                    <tr *ngIf="user.logged_at">
                      <td>
                        <label>{{ 'LAST_LOGIN' | translate }}</label>
                      </td>
                      <td>
                        <label>{{
                          user.logged_at + ' UTC' | date: 'd MMMM y H:mm'
                        }}</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card mb-4">
                <div class="card-header">{{ 'PASSWORD' | translate }}</div>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <label for="newPassword">
                          {{ 'PASSWORD' | translate }}
                        </label>
                      </td>
                      <td>
                        <input
                          type="password"
                          [(ngModel)]="user.password"
                          id="newPassword"
                          name="newPassword"
                          class="form-control"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="newPasswordRepeat">
                          {{ 'REPEAT_PASSWORD' | translate }}
                        </label>
                      </td>
                      <td>
                        <input
                          type="password"
                          [(ngModel)]="user.repeat"
                          id="newPasswordRepeat"
                          name="newPasswordRepeat"
                          class="form-control"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
