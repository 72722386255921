import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Membership, MembershipType, Person } from '../all.model';

@Component({
  templateUrl: './membership.component.html',
})
export class MembershipComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  id: number;
  person: Person;

  member: Membership = {};

  types: MembershipType[];

  new = false;

  constructor(private http: HttpClient, private modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.http
      .get('/api/membership-types')
      .subscribe((types: MembershipType[]) => {
        this.types = types;

        if (!this.id) {
          this.new = true;
          this.member._membershipType = types[0];
          this.member.registerdate = new Date().toJSON().substring(0, 10);
          this.member._person = this.person;
        } else {
          this.http
            .get(`/api/membership/${this.id}`)
            .subscribe((data: Membership): void => {
              this.member = data;
            });
        }
      });
  }

  save(): void {
    this.member.membership_type_id = this.member._membershipType.id;

    this.http.post('/api/membership', this.member).subscribe(this.modal.close);
  }

  delete(): void {
    this.http
      .delete(`/api/membership/${this.member.id}`)
      .subscribe(this.modal.close);
  }

  close(): void {
    this.modal.dismiss();
  }
}
