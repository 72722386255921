import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Person } from '../all.model';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './sepa.component.html',
})
export class SepaComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  year: string;
  date: string;
  types: any[];
  years: string[];

  missingPeople: Person[] = [];

  constructor(
    private http: HttpClient,
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private utility: UtilityService,
  ) {}

  ngOnInit(): void {
    this.http.get('/api/membership-type/sepa').subscribe((types: any[]) => {
      this.types = types;

      this.years = types
        .reduce(
          (arr: string[], type) =>
            arr.concat(type.years.map((y: any) => y.year)),
          [],
        )
        .filter(
          (value: string, index: number, self: string[]) =>
            self.indexOf(value) === index,
        )
        .sort((a: string, b: string) => b.localeCompare(a));

      this.year = this.years[0];

      this.downloadMissingData();
    });
  }

  downloadMissingData(): void {
    this.http
      .get(`/api/person/missing-data?year=${this.year}`)
      .subscribe((persons: Person[]) => {
        this.missingPeople = persons;
      });
  }

  download(): void {
    this.http
      .post(
        '/api/membership-type/sepa',
        {
          date: this.date,
          types: this.types,
          year: this.year,
        },
        {
          observe: 'response',
          responseType: 'blob' as 'json',
        },
      )
      .subscribe((response: HttpResponse<Blob>) => {
        this.utility.download(response);
      });
  }

  showYear(year: string): boolean {
    return (
      this.years.findIndex(y => y === year) >=
      this.years.findIndex(y => y === this.year)
    );
  }

  get yearsLength(): number {
    return this.years.filter(y => this.showYear(y)).length;
  }

  getByYear(years: any[], year: string): number {
    return years.findIndex((a: any) => a.year === year);
  }

  missingDataPeople(modal: any): void {
    this.modalService.open(modal);
  }

  close(): void {
    this.modal.dismiss();
  }
}
