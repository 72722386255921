import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NotifierService } from 'angular-notifier';
import { diffChars, diffWords } from 'diff';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Cud, Log, Logs } from '../all.model';
import { TranslateService } from '../translate.service';

@Component({
  templateUrl: './log.component.html',
})
export class LogComponent implements OnInit {
  Cud: typeof Cud = Cud;

  logs: Log[];

  currentPage = 1;
  cudFilter: Cud;
  searchFilter: string;
  amountLogs: number;
  totalPages: number;

  pageSize: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];

  search: Subject<string>;

  constructor(
    private http: HttpClient,
    private notifier: NotifierService,
    private titleService: Title,
    private translateService: TranslateService,
  ) {
    this.search = new Subject<string>();
    this.search.pipe(debounceTime(200)).subscribe((search: string): void => {
      this.searchFilter = search;
      this.load();
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Logs');

    this.load();
  }

  load(page: number = this.currentPage): void {
    let url = `/api/logs/${page}`;
    if (this.cudFilter) {
      url += `?cudFilter=${this.cudFilter}`;
    }
    if (this.searchFilter) {
      url += `${this.cudFilter ? '&' : '?'}query=${this.searchFilter}`;
    }
    this.http.get(url).subscribe((data: Logs): void => {
      if (data.totalCount === 0) {
        this.amountLogs = data.totalCount;
        return;
      }

      if (page < 1 || page > data.pageCount) {
        return;
      }

      this.logs = data.logs;
      this.totalPages = data.pageCount;
      this.amountLogs = data.totalCount;

      this.logs
        .filter(l => l.change_type === Cud.U)
        .forEach(log => {
          for (const key in log.changes) {
            const c = log.changes[key];

            c.old = String(c.old ?? '');
            c.new = String(c.new ?? '');

            let pieces = diffChars(c.old, c.new);
            if (
              pieces.filter(p => (p.added || p.removed) && p.count > 2).length
            ) {
              pieces = diffWords(c.old, c.new);
            }

            c.old = '';
            c.new = '';
            for (const piece of pieces) {
              if (piece.removed) {
                c.old += `<span class="accent-danger">${piece.value}</span>`;
              } else if (piece.added) {
                c.new += `<span class="accent-success">${piece.value}</span>`;
              } else {
                c.old += piece.value;
                c.new += piece.value;
              }
            }
          }
        });

      this.currentPage = page;

      if (this.totalPages <= 10) {
        this.startPage = 1;
        this.endPage = this.totalPages;
      } else {
        if (page <= 6) {
          this.startPage = 1;
          this.endPage = 10;
        } else if (page + 4 >= this.totalPages) {
          this.startPage = this.totalPages - 9;
          this.endPage = this.totalPages;
        } else {
          this.startPage = page - 5;
          this.endPage = page + 4;
        }
      }

      this.startIndex = (page - 1) * this.pageSize;
      this.endIndex = Math.min(
        this.startIndex + this.pageSize - 1,
        this.amountLogs - 1,
      );

      this.pages = Array(this.endPage - this.startPage + 1)
        .fill(0)
        .map((_, i: number): number => this.startPage + i);
    });
  }

  revert(log: Log): void {
    this.http
      .get(`/api/log/${log.id}/revert`)
      .subscribe((response: boolean): void => {
        if (response) {
          this.notifier.notify(
            'success',
            this.translateService.translate('REVERTED'),
          );
          this.load();
        } else {
          this.notifier.notify(
            'error',
            this.translateService.translate('NOT_REVERTED'),
          );
        }
      });
  }

  setFilter(cud: Cud): void {
    this.cudFilter === cud ? (this.cudFilter = null) : (this.cudFilter = cud);

    this.load(1);
  }
}
