<form #form="ngForm" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">
      {{
        (new ? 'CREATE_MEMBERSHIPTYPE' : 'CHANGE_MEMBERSHIPTYPE') | translate
      }}
    </h4>
    <button
      type="button"
      class="btn float-right"
      tabindex="-1"
      (click)="close()"
    >
      <i class="fa fa-times fa-lg"></i>
    </button>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td>
          <label for="name">{{ 'NAME' | translate }}</label>
        </td>
        <td>
          <input
            type="text"
            [(ngModel)]="type.name"
            id="name"
            name="name"
            class="form-control"
          />
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="delete()"
      *ngIf="!new"
    >
      {{ 'DELETE' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="!form.form.dirty"
    >
      {{ (new ? 'ADD' : 'SAVE') | translate }}
    </button>
  </div>
</form>
