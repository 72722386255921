<form #form="ngForm" (submit)="save()">
  <div class="modal-header">
    <h4 class="modal-title">
      {{
        (type === 'chapter'
          ? new
            ? 'CREATE_CHAPTER_MEMBER'
            : 'CHANGE_CHAPTER_MEMBER'
          : new
          ? 'CREATE_COMMITTEE_MEMBER'
          : 'CHANGE_COMMITTEE_MEMBER'
        ) | translate
      }}
    </h4>
    <button
      type="button"
      class="btn float-right"
      tabindex="-1"
      (click)="close()"
    >
      <i class="fa fa-times fa-lg"></i>
    </button>
  </div>
  <table class="table">
    <tbody>
      <tr>
        <td>
          <label>{{ 'PERSON' | translate }}</label>
        </td>
        <td *ngIf="!person">
          <ng-select
            [items]="people | sort: ['lastname']"
            *ngIf="people"
            [(ngModel)]="member._person"
            name="person_id"
            bindLabel="name"
            [placeholder]="'SEARCH_PERSON' | translate"
            [clearable]="false"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span [class.font-italic]="item.sex != 'm'">
                {{ item.name }}
              </span>
            </ng-template>
          </ng-select>
        </td>
        <td *ngIf="person">
          <label>{{ person.name }}</label>
        </td>
      </tr>
      <tr>
        <td>
          <label>
            {{ (type === 'chapter' ? 'CHAPTER' : 'COMMITTEE') | translate }}
          </label>
        </td>
        <td *ngIf="!committee">
          <ng-select
            [items]="committees"
            *ngIf="committees"
            [(ngModel)]="member._committee"
            name="committee_id"
            [placeholder]="
              (type === 'chapter' ? 'SEARCH_CHAPTER' : 'SEARCH_COMMITTEE')
                | translate
            "
            [clearable]="false"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <span>{{ item.name }}</span>
            </ng-template>
          </ng-select>
        </td>
        <td *ngIf="committee">
          <label>{{ committee.name }}</label>
        </td>
      </tr>
      <tr>
        <td>
          <label for="function">{{ 'FUNCTION' | translate }}</label>
        </td>
        <td>
          <input
            type="text"
            [(ngModel)]="member.function"
            name="function"
            id="function"
            class="form-control"
          />
        </td>
      </tr>
      <tr>
        <td>
          <label for="installationdate">{{ 'INSTALLATION' | translate }}</label>
        </td>
        <td>
          <app-date-picker
            [(ngModel)]="member.installationdate"
            id="installationdate"
            name="installationdate"
          ></app-date-picker>
        </td>
      </tr>
      <tr>
        <td>
          <label for="dischargedate">{{ 'DISCHARGE' | translate }}</label>
        </td>
        <td>
          <app-date-picker
            [(ngModel)]="member.dischargedate"
            id="dischargedate"
            name="dischargedate"
          >
          </app-date-picker>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="delete()"
      *ngIf="!new"
    >
      {{ 'DELETE' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="!form.form.dirty"
    >
      {{ (new ? 'ADD' : 'SAVE') | translate }}
    </button>
  </div>
</form>
