import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './info.component.html',
})
export class InfoComponent {
  info: string;

  constructor(private modal: NgbActiveModal) {}

  close(): void {
    this.modal.close();
  }
}
