import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Option } from '../all.model';

@Component({
  templateUrl: './option.component.html',
})
export class OptionComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  id: number;

  option: Option = {
    default: false,
  };

  new = false;

  constructor(private http: HttpClient, private modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.id) {
      this.new = true;
    } else {
      this.http
        .get(`/api/option/${this.id}`)
        .subscribe((data: Option): void => {
          this.option = data;
        });
    }
  }

  save(): void {
    this.http.post('/api/option', this.option).subscribe(this.modal.close);
  }

  delete(): void {
    this.http
      .delete(`/api/option/${this.option.id}`)
      .subscribe(this.modal.close);
  }

  close(): void {
    this.modal.dismiss();
  }
}
