<div class="container">
  <div class="my-3">
    <h2 class="d-flex justify-content-between align-items-center">
      <span>
        {{ (normal ? 'COMMITTEES' : 'CHAPTERS') | translate }}
        <small *ngIf="committee">&nbsp;&raquo; {{ committee.name }}</small>
      </span>

      <span [info]="normal ? 'COMMITTEES_INFO' : 'CHAPTERS_INFO'">
        <i class="fa fa-info-circle"></i>
      </span>
    </h2>
  </div>

  <div class="row">
    <div class="col-md-4" *ngIf="committees">
      <div class="card mb-4 d-none d-md-block">
        <div class="list-group list-group-flush">
          <a
            class="list-group-item list-group-item-action"
            [class.active]="committee && committee.id === c.id"
            *ngFor="let c of committees | sort: ['name']"
            routerLink="/{{ type }}/{{ c.id }}"
          >
            <i class="float-right">{{ c.committeeMembers }}</i>
            {{ c.name }}
          </a>
          <div class="list-group-item text-center" *ngIf="!committees.length">
            <i>{{ (normal ? 'NO_COMMITTEES' : 'NO_CHAPTERS') | translate }} </i>
          </div>
        </div>
      </div>

      <ng-select
        [items]="committees | sort: ['name']"
        (change)="router.navigate([type, $event.id])"
        [placeholder]="
          (normal ? 'SEARCH_COMMITTEE' : 'SEARCH_CHAPTER') | translate
        "
        class="big-select d-block d-md-none"
        bindLabel="name"
        [clearable]="false"
      >
        <ng-template ng-option-tmp let-item="item">
          {{ item.name }}
          <span class="float-right font-weight-normal">
            {{ item.committeeMembers }}
          </span>
        </ng-template>
      </ng-select>
    </div>

    <div class="col-md-8">
      <form #form="ngForm" (submit)="save()">
        <div>
          <button
            class="btn btn-big btn-outline-success mr-2"
            [disabled]="!committee || !form.form.dirty"
            type="submit"
          >
            <span class="d-none d-sm-block">{{ 'SAVE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-pencil fa-lg"></i>
          </button>
          <button
            type="button"
            class="btn btn-big btn-outline-danger"
            (click)="delete()"
            [disabled]="!committee || !committee.id || form.form.dirty"
          >
            <span class="d-none d-sm-block">{{ 'DELETE' | translate }}</span>
            <i class="d-block d-sm-none fa fa-times fa-lg"></i>
          </button>
          <button
            class="btn btn-big btn-outline-primary float-right"
            routerLink="/{{ type }}/0"
            [disabled]="form.form.dirty"
          >
            <span class="d-none d-sm-block">
              {{ (normal ? 'NEW_COMMITTEE' : 'NEW_CHAPTER') | translate }}
            </span>
            <i class="d-block d-sm-none fa fa-plus fa-lg"></i>
          </button>
        </div>
        <div class="text-center" *ngIf="!committee">
          <p>
            {{ (normal ? 'SELECT_COMMITTEE' : 'SELECT_CHAPTER') | translate }}
          </p>
        </div>

        <div *ngIf="committee">
          <div class="row">
            <div class="col-lg-6">
              <div class="card mb-4">
                <div class="card-header">
                  {{ (normal ? 'COMMITTEE' : 'CHAPTER') | translate }}
                </div>
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <label for="name">{{ 'NAME' | translate }}</label>
                      </td>
                      <td>
                        <input
                          type="text"
                          [(ngModel)]="committee.name"
                          name="name"
                          id="name"
                          class="form-control"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="card mb-4">
                <div class="card-header">{{ 'COMMENTS' | translate }}</div>
                <textarea
                  class="form-control form-comments"
                  [(ngModel)]="committee.comments"
                  name="comments"
                ></textarea>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="card mb-4">
                <div
                  class="card-header d-flex justify-content-between align-items-center"
                >
                  <span>{{ 'MEMBERS' | translate }}</span>
                  <div>
                    <button
                      (click)="committeeMember()"
                      class="btn btn-xs btn-outline-primary"
                      type="button"
                      [disabled]="!committee.id"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="list-group list-group-flush">
                  <a
                    routerLink="/person/{{ c._person.id }}"
                    class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                    *ngFor="let c of committee._committeeMembers"
                    [class.list-group-item-success]="active(c.dischargedate)"
                  >
                    <span>{{ c._person.name }}</span>
                    <div>
                      <i>{{ c.function }}</i>
                      <button
                        (click)="committeeMember(c)"
                        class="btn btn-xs btn-outline-success ml-3"
                        type="button"
                      >
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
