export const nl = {
  MEMBER_DATABASE: 'Ledendatabase',
  MEMBERS: 'Leden',
  PERSONS: 'Personen',
  OPTIONS: 'Opties',
  MEMBERSHIPS: 'Lidmaatschappen',
  BOARDS: 'Besturen',
  CHAPTERS: 'Disputen',
  COMMITTEES: 'Commissies',
  USERS: 'Gebruikers',
  QUERIES: 'Queries',
  LOGS: 'Logs',
  SETTINGS: 'Instellingen',
  INFORMATION: 'Informatie',
  DASHBOARD_INFO:
    'Hier kan je relevante verjaardagen zien van leden en enkele andere statistieken.',
  PERSONS_INFO:
    'Hier staan alle leden opgeslagen. Een lid kan hier aangemaakt, gewijzigd of verwijderd worden. Mailadressen, telefoonnumers en IBANs worden gevalideerd bij het opslaan. Een telefoonnummer moet in internationale notatie. Het commentaar wordt pas zichtbaar als je er met je muis overheen gaat.',
  OPTIONS_INFO:
    "Hier kan je alle opties beheren die je per lid wilt kunnen aanvinken zoals 'Nieuwsbrief'. Bij het aanmaken kan je aangeven wat de standaardwaarde is. Als dit aangezet wordt, zullen alle leden deze optie aangevinkt krijgen en zal bij het aanmaken van nieuwe leden deze optie alvast aangevinkt zijn. Opties kunnen geëxporteerd worden naar excel.",
  MEMBERSHIPS_INFO:
    'Hier staan alle lidmaatschappen. Zo kan je leden opdelen in verschillende groepen en kan je deze verschillende groepen ook exporteren naar excel per startjaar. Een inschrijfjaar start op 1 augustus. Leden zonder lidmaatschap staan onderaan. Op basis van deze lidmaatschappen kan ook een SEPA geëxporteerd worden voor het innen van het inschrijfgeld. Je stelt hiervoor het jaar in waarover je wilt innen, de datum van afschrijven en per lidmaatschap en per jaar een bedrag. Als dit bedrag nul is, wordt de groep niet opgenomen in de SEPA. Mensen waarvan geen inschrijfdatum of correcte IBAN bekend is, zijn rechtsboven in het scherm via het gele driehoekje te vinden.',
  BOARDS_INFO:
    'Hier kunnen besturen van de vereniging bijgehouden worden met de bijbehorende bestuursleden.',
  CHAPTERS_INFO:
    'Hier kunnen disputen van de vereniging bijgehouden worden met de bijbehorende dispuutsleden.',
  COMMITTEES_INFO:
    'Hier kunnen commissies van de vereniging bijgehouden worden met de bijbehorende commissieleden.',
  USERS_INFO:
    'Hier staan alle gebruikers die toegang hebben tot deze ledendatabase. Ook kunnen hier de wachtwoorden worden aangepast. Als een account geblokkeerd is door te veel inlogpogingen kan dit hier ook opgelost worden.',
  QUERIES_INFO:
    'Hier kan de ruwe database worden bewerkt en uitgelezen voor heel specifieke acties met behulp van SQL. Als je niet weet wat je doet, kun je hier beter vanaf blijven.',
  LOGS_INFO:
    'Elke aanpassing in de database wordt opgeslagen in een log. Deze zijn hier te zien en kunnen af en toe bij een verkeerde handeling ook teruggedraaid worden.',
  LANGUAGE: 'Taal',
  THEME: 'Thema',
  LIGHT: 'Licht',
  DARK: 'Donker',
  YESTERDAY: 'Gisteren',
  TODAY: 'Vandaag',
  TOMORROW: 'Morgen',
  BIRTHDAY: 'jarig',
  MEMBERS_PER_YEAR: 'Leden per jaar',
  MEMBERS_PER_SEX: 'Leden per geslacht',
  MEMBERS_PER_FIRSTNAME: 'Leden per voornaam',
  SAVE: 'Opslaan',
  EDIT: 'Aanpassen',
  ADD: 'Toevoegen',
  DELETE: 'Verwijderen',
  NEW_PERSON: 'Nieuw persoon',
  NEW_BOARD: 'Nieuw bestuur',
  NEW_COMMITTEE: 'Nieuwe commissie',
  NEW_CHAPTER: 'Nieuw dispuut',
  NEW_MEMBERSHIPTYPE: 'Nieuw lidmaatschaptype',
  NO_MEMBERSHIPTYPES: 'Er zijn geen lidmaatschaptypes gevonden!',
  NO_MEMBERSHIPTYPES_WITH: 'Er zijn geen lidmaatschappen gevonden bij',
  NO_MEMBERSHIPTYPES_WITHOUT:
    'Er zijn geen mensen gevonden zonder lidmaatschap!',
  NO_MEMBERSHIP: 'Geen lidmaatschap',
  NONE: 'Geen',
  EXPIRED: 'Verlopen',
  NEW_OPTION: 'Nieuwe optie',
  DOWNLOAD_SEPA: 'SEPA downloaden',
  PERSON: 'Persoon',
  SEARCH_PERSON: 'Zoek persoon...',
  SEARCH_PERSON_ABOVE: 'Zoek naar een persoon hierboven!',
  MISSING_DATA: 'Missende data',
  MISSES_DATA:
    'mist een IBAN of tekendatum en kan daarom niet meegenomen worden in de SEPA.',
  FIRST_NAME: 'Voornaam',
  INITIALS: 'Initialen',
  PREFIX: 'Tussenvoegsel',
  LAST_NAME: 'Achternaam',
  SEX: 'Geslacht',
  MALE: 'Man',
  FEMALE: 'Vrouw',
  OTHER: 'Anders',
  DATE_OF_BIRTH: 'Geboortedatum',
  COMMENTS: 'Commentaar',
  DATA: 'Gegevens',
  MAIL_ADDRESS: 'Mailadres',
  PHONE_NUMBER: 'Telefoon',
  IBAN: 'IBAN',
  SIGN_DATE: 'Tekendatum',
  ADDRESS: 'Adres',
  ZIPCODE: 'Postcode',
  TOWN: 'Stad',
  COUNTRY: 'Land',
  ST: 'ste',
  ND: 'de',
  RD: 'de',
  TH: 'de',
  NO_BOARDS: 'Geen besturen gevonden...',
  SEARCH_BOARD: 'Zoek bestuur...',
  SELECT_BOARD: 'Selecteer een bestuur!',
  BOARD: 'Bestuur',
  NAME: 'Naam',
  NUMBER: 'Nummer',
  INSTALLATION: 'Installatie',
  DISCHARGE: 'Decharge',
  CREATE_BOARD_MEMBER: 'Bestuurslid aanmaken',
  CHANGE_BOARD_MEMBER: 'Bestuurslid aanpassen',
  FUNCTION: 'Functie',
  SEARCH_CHAPTER: 'Zoek dispuut...',
  NO_COMMITTEES: 'Geen commissies gevonden...',
  NO_CHAPTERS: 'Geen disputen gevonden...',
  COMMITTEE: 'Commissie',
  CHAPTER: 'Dispuut',
  SEARCH_COMMITTEE: 'Zoek commissie...',
  SELECT_COMMITTEE: 'Selecteer een commissie!',
  SELECT_CHAPTER: 'Selecteer een dispuut!',
  CREATE_CHAPTER_MEMBER: 'Dispuutslid aanmaken',
  CHANGE_CHAPTER_MEMBER: 'Dispuutslid aanpassen',
  CREATE_COMMITTEE_MEMBER: 'Commissielid aanmaken',
  CHANGE_COMMITTEE_MEMBER: 'Commissielid aanpassen',
  SEARCH_LOG: 'Zoek log in het afgelopen halfjaar...',
  CREATION_LOGS: 'Aanmaaklogs',
  CHANGE_LOGS: 'Aanpaslogs',
  DELETION_LOGS: 'Verwijderlogs',
  NO_LOGS: 'Er zijn geen logs gevonden!',
  REVERT: 'Draai terug',
  ATTRIBUTE: 'Attribuut',
  OLD_VALUE: 'Oude waarde',
  NEW_VALUE: 'Nieuwe waarde',
  REVERTED: 'Succesvol teruggedraaid.',
  NOT_REVERTED: 'Niet gelukt terug te draaien...',
  TYPE: 'Type',
  SEARCH_TYPE: 'Zoek type...',
  REGISTRATION: 'Registratie',
  DEREGISTRATION: 'Deregistratie',
  CREATE_MEMBERSHIP: 'Lidmaatschap aanmaken',
  CHANGE_MEMBERSHIP: 'Lidmaatschap aanpassen',
  CREATE_OPTION: 'Optie aanmaken',
  CHANGE_OPTION: 'Optie aanpassen',
  DEFAULT: 'Standaard',
  ON: 'Aan',
  OFF: 'Uit',
  NO_OPTIONS: 'Er zijn geen opties gevonden!',
  SEARCH_QUERY: 'Zoek query...',
  EXECUTE: 'Uitvoeren',
  EXPORT: 'Exporteren',
  YEAR: 'Jaar',
  DEPRECIATION: 'Afschrijving',
  COSTS: 'Kosten',
  PEOPLE_MISSING_DATA: 'Mensen zonder tekendatum of IBAN',
  DOWNLOAD: 'Downloaden',
  YOU_SURE: 'Zeker weten?',
  BACK: 'Terug',
  CONTINUE: 'Doorgaan',
  NEW_USER: 'Nieuwe gebruiker',
  SEARCH_USER: 'Zoek gebruiker...',
  NO_USERS: 'Geen gebruikers gevonden...',
  SEARCH_USER_ABOVE: 'Zoek naar een gebruiker hierboven!',
  ATTEMPTS: 'Pogingen',
  LAST_LOGIN: 'Laatste login',
  REPEAT_PASSWORD: 'Herhaal wachtwoord',
  NOT_FOUND: 'Niets gevonden...',
  LOADING: 'Aan het laden...',
  SAVED: 'Wijzigingen opgeslagen.',
  DELETED: 'is succesvol verwijderd.',
  NOT_DELETED: 'kon niet verwijderd worden.',
  MAYBE_USER: 'Misschien is hij een gebruiker?',
  LOGIN: 'Inloggen',
  LOGOUT: 'Uitloggen',
  PASSWORD: 'Wachtwoord',
  USER: 'Gebruiker',
  SEE_YOU_NEXT_TIME: 'Tot de volgende keer',
  NOT_LOGGEDIN:
    'De inloggegevens waren onjuist of je hebt het te vaak geprobeerd. Vraag een admin om hulp.',
  CREATE_MEMBERSHIPTYPE: 'Lidmaatschaptype aanmaken',
  CHANGE_MEMBERSHIPTYPE: 'Lidmaatschaptype aanpassen',
  NO_RESULTS: 'Er zijn geen resultaten.',
  ARE_YOU_SURE_QUERY: 'Weet je zeker dat je deze {}-query uit wilt voeren?',
  ONE_RESULT: 'Er is 1 resultaat',
  ANY_RESULTS: 'Er zijn {} resultaten.',
  RESULTS: 'De query was succesvol.',
  NO_PASSWORD: 'Geen wachtwoord opgegeven.',
  PASSWORD_TOO_SHORT: 'Het wachtwoord is te kort.',
  PASSWORDS_NOT_EQUAL: 'De opgegeven wachtwoorden zijn niet gelijk.',
  NOT_ALL_FIELDS: 'Niet alle velden zijn correct ingevuld... ',
  SOME_ERROR: 'Er is iets misgegaan op de server...',
  ARE_YOU_SURE_DELETE: 'Weet je zeker dat je {} wilt verwijderen?',
  UNSAVE:
    'Je hebt wijzigingen gemaakt die nog niet zijn opgeslagen. Toch doorgaan?',
};
