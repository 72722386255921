import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { Committee, CommitteeMember, CommitteeType } from '../all.model';
import { CommitteeMemberComponent } from '../committee-member/committee-member.component';
import { TranslateService } from '../translate.service';
import { UtilityService } from '../utility.service';

@Component({
  templateUrl: './committee.component.html',
})
export class CommitteeComponent implements OnInit {
  @ViewChild('form') form: NgForm;

  type: CommitteeType;
  normal: boolean;

  committee: Committee;

  committees: Committee[] = [];

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title,
    public router: Router,
    private notifier: NotifierService,
    private modalService: NgbModal,
    private utility: UtilityService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: any): void => {
      this.type = data.type;
      this.normal = this.type === CommitteeType.committee;

      this.all();

      this.route.paramMap.subscribe((paramsMap: ParamMap): void => {
        if (paramsMap.get('id')) {
          if (paramsMap.get('id') === '0') {
            if (this.form) {
              this.form.control.markAsDirty();
            }
            this.committee = { type: this.type } as Committee;
            this.titleService.setTitle(
              this.translateService.translate(
                this.normal ? 'COMMITTEES' : 'CHAPTERS',
              ),
            );
          } else {
            this.load(paramsMap.get('id'));
          }
        } else if (localStorage.getItem(this.type)) {
          this.router.navigate([
            `${this.type}/${localStorage.getItem(this.type)}`,
          ]);
        } else {
          this.committee = null;
          this.titleService.setTitle(
            this.translateService.translate(
              this.normal ? 'COMMITTEES' : 'CHAPTERS',
            ),
          );
        }
      });
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.form.control.dirty) {
      $event.returnValue = true;
    }
  }

  private all(): void {
    this.http.get('/api/committees').subscribe((data: Committee[]): void => {
      this.committees = data.filter(
        (committee: Committee) => committee.type === this.type,
      );
    });
  }

  private load(id: number | string): void {
    if (this.form) {
      this.form.control.markAsPristine();
    }

    this.http.get(`/api/committee/${id}`).subscribe(
      (data: Committee): void => {
        if (data.type !== this.type) {
          localStorage.removeItem(this.type);
          this.router.navigate([`${this.type}`]);

          return;
        }

        localStorage.setItem(this.type, id.toString());
        this.committee = data;
        this.titleService.setTitle(
          data
            ? data.name
            : this.translateService.translate(
                this.normal ? 'COMMITTEES' : 'CHAPTERS',
              ),
        );
        this.all();
      },
      (): void => {
        localStorage.removeItem(this.type);
        this.router.navigate([`${this.type}`]);
      },
    );
  }

  save(): void {
    if (!this.form.control.dirty) {
      return;
    }

    this.http
      .post('/api/committee', this.committee)
      .subscribe((data: Committee): void => {
        this.form.control.markAsPristine();
        this.notifier.notify(
          'success',
          this.translateService.translate('SAVED'),
        );
        this.committee = data;
        this.router.navigate([`${this.type}/${data.id}`]);
      });
  }

  delete(): void {
    this.utility.delete(this.committee.name).subscribe((sure: boolean) => {
      if (sure) {
        this.http
          .delete(`/api/committee/${this.committee.id}`)
          .subscribe((success: boolean): void => {
            if (success) {
              this.notifier.notify(
                'success',
                `${this.committee.name} ${this.translateService.translate(
                  'DELETED',
                )}`,
              );
              this.form.control.markAsPristine();
              localStorage.removeItem(this.type);
              this.router.navigate([`${this.type}`]);
            } else {
              this.notifier.notify(
                'error',
                `${this.committee.name} ${this.translateService.translate(
                  'NOT_DELETED',
                )}`,
              );
            }
          });
      }
    });
  }

  committeeMember(member: CommitteeMember): void {
    if (!this.committee.id) {
      return;
    }

    const modal = this.modalService.open(CommitteeMemberComponent);
    modal.componentInstance.id = member ? member.id : null;
    modal.componentInstance.committee = this.committee;
    modal.componentInstance.type = this.type;

    modal.result.then(
      () => {
        this.load(this.committee.id);
      },
      () => {},
    );
  }

  active(date: string): boolean {
    return !date || new Date(date) > new Date();
  }
}
